/* eslint-disable no-console */
import React, { useState } from "react";
import PropTypes from "prop-types";
import SelectInput from "@cx/ui/SelectInput";

function VehicleAttributeSelector(props) {
  const [partsAndLabor, setPartsAndLabor] = useState(props.partsAndLabor);
  const [selectableVehicleAttributes, setSelectableVehicleAttributes] =
    useState(partsAndLabor.selectableVehicleAttributes);

  // React.useEffect(() => {
  //   console.log(partsAndLabor);
  //   setPartsAndLabor(partsAndLabor);
  //   setSelectableVehicleAttributes(partsAndLabor.selectableVehicleAttributes);
  // }, [partsAndLabor]);

  React.useEffect(() => {
    console.log(props.partsAndLabor);
    setPartsAndLabor(props.partsAndLabor);
    setSelectableVehicleAttributes(
      props.partsAndLabor.selectableVehicleAttributes
    );
  }, [props.partsAndLabor, props.partsAndLabor.selectableVehicleAttributes]);

  function renderVehAttrSelectors() {
    return selectableVehicleAttributes.map((vehAttrName, index) => {
      const name = vehAttrName;
      const key = `key_${index}`;
      const htmlId = `htmlId_${index}`;
      const label = vehAttrName;
      const { vehicleAttributes } = partsAndLabor;
      const vehAttrList = vehicleAttributes[vehAttrName];
      if (!vehAttrList || !Array.isArray(vehAttrList)) {
        return "";
      }
      const options = vehAttrList.map(item => {
        return { label: item, value: item };
      });
      options.push({ label: "I don't know", value: "idk" });

      return (
        <SelectInput
          htmlId={htmlId}
          label={label}
          name={name}
          key={key}
          onChange={(cxEvent, isValid, domEvent) =>
            props.onChange(cxEvent, isValid, domEvent)
          }
          options={options}
        />
      );
    });
  }
  let dropdowns = "";
  if (selectableVehicleAttributes && selectableVehicleAttributes.length !== 0) {
    dropdowns = renderVehAttrSelectors();
  }
  return (
    <React.Fragment>
      <div className="select-container-vehicle-attributes">{dropdowns}</div>
    </React.Fragment>
  );
}

export default VehicleAttributeSelector;

VehicleAttributeSelector.propTypes = {
  partsAndLabor: PropTypes.object,
  onChange: PropTypes.func
};
