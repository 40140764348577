import React, { useState } from "react";
import PropTypes from "prop-types";
import ModalDialog from "@cx/ui/ModalDialog";
import Button from "@cx/ui/Button";
import cloneDeep from "lodash/cloneDeep";
import "./documents-modal.scss";

const DocumentsModal = props => {
  // IMPORTANT: The name property values below are used as API URL segments,
  // so make sure they match the API URL segments after "/docs/" here:
  // https://csrapi.dev6.xtimeappsnp.xtime.com/rest/swagger-ui/#/eforms-resource
  const [docs, setDocs] = useState([
    {
      name: "serviceEstimate",
      label: "Service estimate",
      isChecked: false
    },
    {
      name: "serviceInvoice",
      label: "Service invoice",
      isChecked: false
    },
    {
      name: "techHardCard",
      label: "Tech hard card",
      isChecked: false
    }
  ]);

  const onPrintClicked = () => {
    props.printSelectedDocumentsHandler(
      docs.filter(d => d.isChecked).map(d => d.name)
    );
  };

  const updateDocChecked = (name, isChecked) => {
    const updatedDocs = cloneDeep(docs);
    const doc = updatedDocs.find(d => d.name === name);
    doc.isChecked = isChecked;
    setDocs(updatedDocs);
  };

  return (
    <ModalDialog
      htmlId="documentsModal"
      className="documents-modal"
      show={props.show}
      header={<h4 className="modal-title">Documents</h4>}
      footer={
        <div className="documents-modal-footer">
          <div className="external-actions">
            <Button
              htmlId="searchArchiveButton"
              onClick={props.searchArchiveHandler}
              buttonStyle="secondary"
            >
              Search archive
            </Button>
          </div>
          <div className="internal-actions">
            <Button
              htmlId="cancelButton"
              onClick={props.closeHandler}
              buttonStyle="secondary"
            >
              Cancel
            </Button>
            <Button
              htmlId="printButton"
              onClick={onPrintClicked}
              buttonStyle="primary"
              disabled={!docs.some(d => d.isChecked)}
            >
              Print
            </Button>
          </div>
        </div>
      }
      onHide={props.closeHandler}
    >
      <div className="doc-checkbox-group">
        {docs.map(doc => {
          return (
            <div key={doc.name} className="doc">
              <input
                type="checkbox"
                checked={doc.isChecked}
                value={doc.name}
                onChange={cxEvent => {
                  updateDocChecked(doc.name, cxEvent.target.checked);
                }}
                id={`docCheckbox_${doc.name}`}
                name={`docCheckbox_${doc.name}`}
              />
              <label
                htmlFor={`docCheckbox_${doc.name}`}
                className="docCheckboxLabel"
              >
                {doc.label}
              </label>
            </div>
          );
        })}
      </div>
    </ModalDialog>
  );
};

export default DocumentsModal;

DocumentsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
  searchArchiveHandler: PropTypes.func.isRequired,
  printSelectedDocumentsHandler: PropTypes.func.isRequired
};
