import React, { useEffect, useRef, useState } from "react";
import { string, number, arrayOf, oneOfType, shape } from "prop-types";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import { Footer } from ".";
import useElementHeight from "../util/useElementHeight";

const EventLog = ({ roNumber, logs }) => {
  const gridRef = useRef(null);
  const [domLayout, setDomLayout] = useState("normal");
  const { height: maxGridHeight, ref: outerGridRef } =
    useElementHeight("event-log-grid");
  const [maxGridAvailableSpace, setGridAvailableSpace] = useState(
    maxGridHeight || null
  );

  useEffect(() => {
    const FOOTER_HEIGHT = 53;
    const MARGIN = 20;
    setGridAvailableSpace(maxGridHeight - FOOTER_HEIGHT - MARGIN);
  }, [maxGridHeight]);

  const onFilterTextBoxChanged = event => {
    if (event) {
      event.preventDefault();
    }
    const searchKey = event.target.value;
    gridRef.current?.api.setQuickFilter(searchKey);
  };

  const handleExportCSV = () => {
    const exportParams = {
      fileName: `RO${roNumber}-ActivityLog.csv` // Custom filename
    };
    gridRef.current?.api.exportDataAsCsv(exportParams);
  };

  const handlePrint = () => {
    if (gridRef.current) {
      gridRef.current.eGridDiv.style.width = "700px";
      gridRef.current.api.sizeColumnsToFit();

      window.print();

      gridRef.current.eGridDiv.style.width = "";

      gridRef.current.api.sizeColumnsToFit({
        columnLimits: [
          { key: "timestamp", minWidth: 150, flex: 2 },
          { key: "description", minWidth: 200, flex: 4 },
          { key: "serviceLineNumber", minWidth: 50, flex: 1 },
          { key: "user", minWidth: 100, flex: 1 },
          { key: "event", minWidth: 80, flex: 2 }
        ]
      });
    }
  };

  useEffect(() => {
    setGridDomLayout();
  }, [maxGridAvailableSpace]);

  function calcGridRowsHeight() {
    const oneRowHeight = 28;
    const allRowsHeight = oneRowHeight * logs?.length;
    return allRowsHeight;
  }
  function setGridDomLayout() {
    const allRowsHeight = calcGridRowsHeight();

    // if rows height <= max Space
    if (logs) {
      const newDomLayout =
        allRowsHeight <= maxGridAvailableSpace ? "autoHeight" : "normal";
      document.querySelector(".ro-grid").firstChild.style.height =
        allRowsHeight <= maxGridAvailableSpace
          ? "fit-content"
          : `${maxGridAvailableSpace}px`;

      if (domLayout !== newDomLayout) {
        gridRef.current?.api.setDomLayout(newDomLayout);
        setDomLayout(newDomLayout);
      }
    }
  }

  const onGridReady = () => {
    setTimeout(() => {
      setGridDomLayout();
    }, 200);
  };

  const columnDefs = [
    {
      headerName: "Date and time",
      field: "timestamp",
      resizable: true,
      minWidth: 150,
      suppressSizeToFit: true,
      suppressMovable: true,
      sortable: true,
      sort: "desc",
      flex: 2,
      valueFormatter: params => {
        const date = moment(params?.value).format("ddd M/D/YY hh:mm A");
        return date;
      }
    },
    {
      headerName: "Description",
      field: "description",
      resizable: true,
      minWidth: 200,
      sortable: true,
      suppressMovable: true,
      flex: 4
    },
    {
      headerName: "Line",
      field: "serviceLineNumber",
      resizable: true,
      minWidth: 50,
      suppressSizeToFit: true,
      suppressMovable: true,
      sortable: true,
      flex: 1
    },
    {
      headerName: "Employee",
      field: "user",
      resizable: true,
      minWidth: 100,
      suppressSizeToFit: true,
      suppressMovable: true,
      sortable: true,
      flex: 1,
      valueFormatter(params) {
        return params.value ?? "--";
      }
    },
    {
      headerName: "Event category",
      field: "event",
      minWidth: 100,
      suppressSizeToFit: true,
      suppressMovable: true,
      resizable: true,
      sortable: true,
      flex: 2
    }
  ];

  return (
    <>
      <input
        data-testid="ro-search-box"
        type="text"
        id="ro-search-box"
        className="searchInput"
        onInput={onFilterTextBoxChanged}
      />
      <div
        className="ag-grid-container ag-theme-balham ro-grid event-log-grid"
        ref={outerGridRef}
      >
        <AgGridReact
          ref={gridRef}
          rowData={logs}
          columnDefs={columnDefs}
          containerStyle={{ height: "60%" }}
          overlayNoRowsTemplate="No rows to show"
          enableCellTextSelection={true}
          onGridReady={onGridReady}
        />
      </div>
      <Footer onPrint={handlePrint} onExport={handleExportCSV} />
    </>
  );
};

EventLog.propTypes = {
  roNumber: string,
  logs: arrayOf(
    shape({
      timestamp: string,
      description: string,
      user: string,
      event: string,
      serviceLineNumber: oneOfType([number, string])
    })
  )
};

export default EventLog;
