import { roStatusOptions } from "../../../constants/app.constants";
import { paymentStatus } from "../../../constants/quote-status.constants";

export const transform3PPToQuoteSummary = data => {
  const { roSeqId, roId, repairOrder } = data;
  const {
    status,
    hangtag,
    promisedDate,
    openDate,
    closeDate,
    customer,
    mileageIn,
    mileageOut,
    services,
    payers,
    vehicle,
    eventLogs,
    transfers = []
  } = repairOrder;
  return {
    confirmationId: roSeqId.toString(),
    quoteId: roSeqId,

    // Header
    roNumber: +roId,
    quoteStatus: status ?? roStatusOptions.FINALIZED,
    hangTag: hangtag ?? "--",
    pickUpDateTime: promisedDate,
    transportation: getTransportation(repairOrder),

    // Sub Header
    advisor: getAdvisor(repairOrder),
    counterPartsPerson: getPartsPerson(repairOrder),
    checkedInDateTime: openDate,
    closedDateTime: closeDate,

    // Customer / Vehicle
    customer: getCustomer(customer),
    mileageIn: mileageIn.mileage,
    mileageOut: mileageOut.mileage,
    vehicle: getVehicle(vehicle),

    // Service Details
    quoteServices: services.map(getService),

    // Checkout Summary
    payers: getPayers(payers),
    eventLogs,
    transfers
  };
};

const getTransportation = ({ transportationType }) => ({
  start: {
    transportType: transportationType
  }
});

const getAdvisor = ({ advisorFirstName, advisorLastName }) => ({
  firstName: advisorFirstName ?? "--",
  lastName: advisorLastName ?? "--"
});

const getPartsPerson = ({
  partsCounterPersonFirstName,
  partsCounterPersonLastName
}) => ({
  firstName: partsCounterPersonFirstName ?? "--",
  lastName: partsCounterPersonLastName ?? "--"
});

export const getCustomer = customer => {
  const { emails, phones } = customer;

  return {
    personId: customer.customerId ?? "123456789", // personId IS REQUIRED or this RO gets treated as SQ
    firstName: customer.name?.firstName ?? "",
    lastName: customer.name?.lastName || customer.name || "--",
    contactInfo: {
      phoneNumbers:
        phones && Array.isArray(phones) && phones.length > 0
          ? phones.reduce((prev, curr) => {
              if (!curr.phoneNumber) return prev; // Without a phoneNumber this item is unusable, skip it

              if (curr.type === "CELL") {
                // 3PP has a CELL type but CSR recognizes MOBILE
                prev["MOBILE"] = curr.phoneNumber;
              } else {
                // Sometimes type can be null. If we have a phoneNumber, set the type to HOME as fallback
                prev[curr.type ?? "HOME"] = curr.phoneNumber;
              }
              return prev;
            }, {})
          : {}
    },
    email: emails?.[0].email ?? "--"
  };
};

export const getVehicle = vehicle => {
  const { description, vin, specifications } = vehicle;

  return {
    metaVehicleId: 987654321, // metaVehicleId IS REQUIRED or no vehicle details will render
    make: description?.make ?? "--",
    model: description?.model?.marketingName ?? "--",
    year: description?.year ?? "--",
    trim: description?.trim?.marketingName ?? "--",
    engineSize: specifications?.engineSize ?? "--",
    driveType: specifications?.driveType ?? "--",
    transmissionType: specifications?.transmissionType ?? "--",
    vin: vin ?? "--"
  };
};

export const getService = service => {
  const {
    payTypeCode,
    completionDate,
    name,
    sequenceNumber,
    retailAmount,
    finalPartsPrice,
    servicePrice,
    lineServicePrice,
    id,
    labor,
    parts,
    sublets,
    technicians,
    cause,
    concern,
    correction,
    serviceType,
    vendorName,
    warrantySubmissionState,
    asrNotes,
    comments,
    notes,
    payTypeDescription,
    departmentSubType,
    costAllocationSubType,
    xtServiceType,
    discounts,
    fees
  } = service;
  return {
    serviceName: name ?? "--",
    serviceLineNumber: sequenceNumber ?? "--",
    finalLaborPrice: retailAmount?.amount ?? labor?.laborAmount?.amount ?? 0,
    finalPartsPrice: finalPartsPrice?.amount ?? 0,
    servicePrice: servicePrice?.amount ?? 0,
    lineServicePrice: lineServicePrice?.amount ?? 0,
    extServiceId: id,
    labor: labor
      ? {
          laborTime: (labor.laborHours * 60).toString(), // convert to minutes
          laborPrice: labor.laborAmount?.amount ?? 0
        }
      : null,
    parts: getParts(parts),
    catalogFees: getCatalogFees(fees),
    catalogDiscounts: getCatalogDiscounts(discounts),
    sublets:
      sublets && Array.isArray(sublets) && sublets.length > 0
        ? sublets.map(sublet => {
            const { vendorName, poNumber, total } = sublet;
            return {
              vendor: vendorName ?? "--",
              poNumber: poNumber ?? "--",
              poPricing: {
                subletTotalCost: total?.amount ?? 0
              }
            };
          })
        : [],
    technicians: getTechnicians(technicians),
    cause,
    complaint: concern,
    correction,
    completedTime: completionDate,
    paymentStatus: paymentStatus.READY,
    warranty: {
      warrantySubmissionState
    },
    asrNotes,
    dealershipNotes: notes,
    payTypeCode,
    quoteRawService: {
      rawService: '{"defaultServiceType": "MR"}'
    },
    serviceTypeCode: serviceType?.code,
    vendorName,
    payTypeGroup: payTypeDescription, // This is supposed to be payTypeGroup but it does not match
    payTypeDescription,
    subType: {
      subType: departmentSubType
    },
    allocationSubType: {
      subType: costAllocationSubType
    },
    quoteServiceType: xtServiceType
  };
};

const getTechnicians = technicians => {
  if (!technicians) return [];

  return technicians
    ?.filter(({ technicianId, firstName, lastName }) => {
      return technicianId || firstName || lastName;
    })
    .map(({ firstName, lastName, flagTime, duration, laborRate }) => ({
      techUser: {
        firstName,
        lastName
      },
      flagTimeEnd: flagTime?.toString(),
      actualTimeDuration: duration,
      laborRateCode: laborRate
    }));
};

const getParts = parts => {
  if (!parts) return [];

  return parts.map(
    ({
      partType,
      uom,
      partNumber,
      partDescription,
      quantity,
      approverID,
      isCorePart,
      oilType,
      unitPrice,
      netPrice
    }) => ({
      partType,
      adjustedUom: uom,
      extPartId: "HARDCODE",
      oemPartNumber: partNumber,
      approver: approverID || "HARDCODE", // Can be anything
      isCorePart,
      description: partDescription,
      oilType,
      adjustedQuantity: quantity,
      unitPrice: unitPrice?.amount,
      partPrice: netPrice?.amount
    })
  );
};

export const getPayers = payers => {
  if (!payers) return [];

  return payers.map(
    ({
      payTypeCode,
      // payerCode,
      payTypeGroup,
      fees,
      discounts,
      closeDate,
      subTotalLineLaborBasePrice,
      subTotalLinePartsBasePrice,
      subTotalLineLaborFees,
      subTotalHazmat,
      miscChargesAndFees,
      subTotalLineDiscounts,
      subTotalRODiscounts,
      subTotalSublets,
      subTotalBeforeTaxes,
      salesTax,
      finalPrice,
      serviceContract
    }) => ({
      catalogDiscounts: getCatalogDiscounts(discounts),
      catalogFees: getCatalogFees(fees),
      payType: payTypeCode ?? payTypeGroup?.substring(0, 1),
      closedDate: closeDate,
      subTotalLineLaborBasePrice: subTotalLineLaborBasePrice?.amount ?? "--",
      subTotalLinePartsBasePrice: subTotalLinePartsBasePrice?.amount ?? "--",
      subTotalLineLaborFees: subTotalLineLaborFees?.amount ?? "--",
      feesHazardousMaterials: subTotalHazmat?.amount ?? "--",
      miscChargesAndFees: miscChargesAndFees?.amount ?? "--",
      subTotalLineDiscounts: subTotalLineDiscounts?.amount ?? "--",
      subTotalRODiscounts: subTotalRODiscounts?.amount ?? "--",
      subTotalSublets: subTotalSublets?.amount ?? "--",
      subTotalBeforeTaxes: subTotalBeforeTaxes?.amount ?? "--",
      salesTax: salesTax?.amount ?? "--",
      finalPrice: finalPrice?.amount ?? "--",
      ...(serviceContract && { serviceContract })
    })
  );
};

const getCatalogFees = fees => {
  if (!fees) return [];

  return fees.map(
    ({
      feeDescription,
      amountType,
      applyFeeSvcLine,
      feesAmountCollection,
      maximumAmount,
      validFrom
    }) => {
      return {
        description: feeDescription,
        feesType: amountType,
        applyFeeSvcLine: applyFeeSvcLine ? 1 : 0,
        appliedFee: feesAmountCollection?.[0].feeAmount?.amount ?? 0,
        feeMax: maximumAmount?.amount,
        fromDate: validFrom
      };
    }
  );
};

const getCatalogDiscounts = discounts => {
  if (!discounts) return [];

  return discounts.map(({ discountAmount, discountDescription }) => {
    return {
      appliedDiscount: discountAmount?.amount,
      description: discountDescription ?? "--"
    };
  });
};
