import React, { useState } from "react";
import PropTypes from "prop-types";
import "./change-mileage-In.scss";
import NumericInput from "@cx/ui/NumericInput";
const ChangeMileageIn = props => {
  const [mileageIn, setMileageIn] = useState(
    props?.quoteSummary?.mileageIn || null
  );

  const onInputChange = cxEvent => {
    setMileageIn(cxEvent.target.value);
    props.onUpdate({
      mileageIn: cxEvent.target.value
    });
  };

  const handleEnterPress = event => {
    /* istanbul ignore else*/
    if (event.key === "Enter") {
      // Call the callback function passed from parent on press of enter
      props.onEnterKeyPressOnUpdate();
    }
  };
  return (
    <div className="change-mileageIn-container">
      <NumericInput
        htmlId="mileageInInput"
        name="mileageInInput"
        label="Mileage in"
        getError={(_lbl, val) => {
          if (!val) return "";
          if (val == 0) return "Mileage cannot be 0";
        }}
        onChange={onInputChange}
        maxLength={6}
        value={mileageIn}
        onKeyDown={handleEnterPress}
        required={true}
      />
    </div>
  );
};

export default ChangeMileageIn;

ChangeMileageIn.propTypes = {
  quoteSummary: PropTypes.object,
  onUpdate: PropTypes.func,
  onEnterKeyPressOnUpdate: PropTypes.func
};

ChangeMileageIn.defaultProps = {
  quoteSummary: {},
  onUpdate: () => {},
  onEnterKeyPressOnUpdate: () => {}
};
