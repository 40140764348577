import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isNull from "lodash/isNull";
import get from "lodash/get";
import AccordionGroup from "@cx/ui/AccordionGroup";
import ServiceDescription from "../components/ServiceDescription";
import AsrNotes from "../components/AsrNotes";
import "./Details.scss";
import LaborComponent from "../components/LaborComponent";
import priceCalculationUtil from "../utils/service-calculation.util";
import { PartsLookupModule } from "../../PartsLookupModule";
import { useEditServiceContext, Actions } from "../state/edit-service.context";
import { actionTypes, operationSources } from "../utils/edit-service.constants";
import CSRDetails from "./csr/csrDetails.container";
import InternalNotes from "../components/csr/internal-notes.component";
import {
  filterToPartDtoFields,
  PartFields
} from "../../PartsLookupModule/utils/helper.util";
import globalOperationsService from "../../features/page-wrapper/services/global-operations.service";
import { appTypes } from "../../constants/app.constants";
import ConcernNotes from "../components/ConcernNotes";
import csrService from "../../features/quote-summary/services/csr.service";

const Details = props => {
  // For Global ops case: wrapOnAccordion is true
  const { sections, wrapOnAccordion, appContext } = props;
  const { localeStrings } = appContext;
  const { dispatch, state } = useEditServiceContext();
  const {
    appType,
    debugMode,
    originalService,
    service: stateService,
    completedSteps,
    filteredParts,
    vehicleAttributes,
    lookupParts,
    currentEditingService,
    commonConsumerId
  } = state;
  const { parts: originalParts, partsPrice: originalPartsPrice } =
    originalService;
  const [expandedStatus, setExpandedStatus] = useState({
    expanded: false,
    disabled: true,
    defaultExpanded: false
  });
  const [serviceOptionSelected, setServiceOptionSelected] = useState(false);
  useEffect(() => {
    if (completedSteps.serviceOptions) {
      setExpandedStatus({ defaultExpanded: true });
      setServiceOptionSelected(true);
    } else {
      setServiceOptionSelected(false);
      setExpandedStatus({
        defaultExpanded: false,
        disabled: true,
        expanded: false
      });
    }
  }, [completedSteps.serviceOptions]);
  /**
   * callback method when parts modified in parts lookup module, partsPrice returned from parts callback handlers
   * @param {number} totalPartsPrice
   * @param {object} modifiedParts
   *
   */
  const updateServiceWithPartsCalculation = (
    totalPartsPrice,
    modifiedParts
  ) => {
    const updatedService = priceCalculationUtil.recalculatePrices(
      stateService,
      actionTypes.PARTS,
      totalPartsPrice,
      modifiedParts
    );
    dispatch({
      type: Actions.SET_SERVICE,
      payload: updatedService
    });
  };

  // Non-Global Service case - selected parts from parts grid forwarded in this props callback; update edit context with latest parts, total parts price
  const getPartsCallback = partsObj => {
    const partsToCompare = filterToPartDtoFields(partsObj.parts, PartFields);
    // Important: To know difference in original parts vs selected parts; remove these properties {rowId,selected} and compare objects
    const originalPartsToCompare = filterToPartDtoFields(
      originalParts,
      PartFields
    );
    // The partsNotEqual needs to be created before push to missing parts
    const partsNotEqual = !isEqual(partsToCompare, originalPartsToCompare);
    // @note: this logic will work when part has partId
    const currentPartsIds = partsToCompare.map(part => part.partId);
    const missingParts = originalPartsToCompare.filter(part => {
      return !currentPartsIds.includes(part.partId);
    });
    if (missingParts.length > 0) {
      partsToCompare.push(...missingParts);
    }
    dispatch({
      type: Actions.SET_CHANGED,
      payload: {
        field: "parts",
        value: partsNotEqual
      }
    });
    // @note: There used to be a check with this condition:
    // (partsNotEqual || stateService.operationSource === operationSources.MENU) to dispatch SET_PARTS.
    // However, this check has been removed because there is the scenario in which the original part is deleted and added back.
    // if parts are diff, dispatch selected parts and totalPartsPrice to context
    dispatch({
      type: Actions.SET_PARTS,
      payload: partsObj.parts
    });
    const partsPriceToCompare = !isNull(partsObj.totalPartsPrice)
      ? partsObj.totalPartsPrice.toFixed(2)
      : null;
    const originalPartsPriceToCompare = !isNull(originalPartsPrice)
      ? originalPartsPrice.toFixed(2)
      : null;
    const partsPriceNotEqual = !isEqual(
      partsPriceToCompare,
      originalPartsPriceToCompare
    );

    dispatch({
      type: Actions.SET_CHANGED,
      payload: {
        field: "partsPrice",
        value: partsPriceNotEqual
      }
    });
    updateServiceWithPartsCalculation(partsObj.totalPartsPrice, partsObj.parts);
  };
  // Global Service case - selected parts from parts grid forwarded in this props callback; update edit context with latest parts, total parts price
  const getPartsCallbackGlobalRepair = partsObj => {
    // Important: To know difference in original parts vs selected parts; remove these properties {rowId,selected} and compare objects
    // @note: extra properties added to parts during process should be removed to compare parts with originalParts if not present in the later
    const partsToCompare = filterToPartDtoFields(partsObj.parts, PartFields);

    // @note: extra properties added to parts during process should be removed to compare parts with originalParts if not present in the later
    const filteredPartsToCompare = filterToPartDtoFields(
      filteredParts,
      PartFields
    );
    // @note: the following operations are only to be made if the user has clicked done button
    if (completedSteps.serviceOptions) {
      const partsNotEqual = !isEqual(partsToCompare, filteredPartsToCompare);
      dispatch({
        type: Actions.SET_CHANGED,
        payload: {
          field: "parts",
          value: partsNotEqual
        }
      });
      if (partsNotEqual) {
        dispatch({
          type: Actions.SET_FILTERED_PARTS,
          payload: partsObj.parts
        });
      }
      const partsPriceNotEqual =
        partsObj.totalPartsPrice !== originalPartsPrice;
      dispatch({
        type: Actions.SET_CHANGED,
        payload: {
          field: "partsPrice",
          value: partsPriceNotEqual
        }
      });
      updateServiceWithPartsCalculation(
        partsObj.totalPartsPrice,
        partsObj.parts
      );
    }
  };

  const dispatchDealershipNotes = dealershipNotes => {
    dispatch({
      type: Actions.SET_DEALERSHIP_NOTES,
      payload: dealershipNotes
    });
  };

  // @csr-logic emergency purchase order
  const getPurchaseOrderDetails = async poNumber => {
    const response = await csrService.getPurchaseOrderDetailsCall(
      poNumber,
      appContext.dealer.dealerCode
    );

    return response;
  };

  const service = {
    ...stateService,
    parts: [...(!isEmpty(filteredParts) ? filteredParts : stateService.parts)]
  };
  const getComponentStack = sections => {
    const compMap = {
      description: !isEmpty(stateService.description) ? (
        <ServiceDescription />
      ) : null,
      asrNotes: !isEmpty(stateService.asrNotes) ? <AsrNotes /> : null,
      laborComponent: !isEmpty(stateService) ? (
        <LaborComponent
          onChangePaytype={props.onChangePaytype}
          onChangeServiceType={props.onChangeServiceType}
          onChangeServiceContract={props.onChangeServiceContract}
          // @todo: pass value by reading from state instead of local const
          defaultPayTypeCode={props.defaultPayTypeOption}
          defaultServiceTypeCode={props.defaultServiceTypeOption}
          axiosInstance={props.axiosInstance}
          dealerCode={props.dealerCode}
        />
      ) : null,
      // @note: partsLookupModule rendered with edit module, so lookupParts, rawOperationDetails are ready first time with partsbased on parts passed as prop
      partsGrid: (
        <PartsLookupModule
          // @required props
          appType={appType}
          debugMode={debugMode}
          userPermissions={state.userPermissions}
          // @required- make passed to support Make dropdown filter logic in Individual Part Tab
          make={!state.vehicle ? "" : state.vehicle.make}
          dealerProperties={appContext?.dealerProperties}
          dealerMakesProperty={
            appContext?.dealerProperties?.MANUFACTURER_MAKE_CODE_LIST
          }
          emergencyPartProperty={
            appContext?.dealerProperties?.ENABLE_DMSPLUS_EMERGENCY_PARTS
          }
          // @required- - expected values "EDIT_SERVICE" or "MODIFY_PARTS"
          // when service edited from summary, we can depend on editcontext -> state.service
          actionType={!currentEditingService ? "EDIT_NEW" : "EDIT_SUMMARY"}
          // @required- Add Service case (search, non-global) - This prop hold catalog getOperation API response level laborApps[0].parts
          // Add Service case (global repair case), state.lookupParts updated using Action SET_LABOR_APP
          // Summary edit case - read QuoteRawService Json string for parts[] from quoteService;
          lookupParts={lookupParts}
          // @required- - All flows - pass parts of opentrack API callback response, to refresh recommended grid and selected parts grid as well
          partsPricingAndInventory={state.partsPricingAndInventory}
          // @required prop - service object used to read service name, operationSource(global, non-global, menu, price override fields) for conditions.
          // Add service case - parts exist in serivce.parts(non-global);,filteredParts(global);
          // Edit service summary (global/non-global)- parts are saved in "filteredParts" using this Action SET_CURRENT_EDITING_SERVICE
          service={service}
          // !remove prop - Edge case - Either remove this prop and use service.parts condtions in parts lookup files; This case only used summary edit service case - filteredParts -> parts extracted from quoteService
          savedParts={!state.currentEditingService ? [] : filteredParts}
          // !remove prop - Edge case to cover if global repair service has undecode vehicl attrs to show extra grid columns
          selectedVehicleAttributes={vehicleAttributes}
          // !remove prop - global repair case, these steps used whether service options dropdown selected, to allow used to click on "MODIFY Parts" button in edit service page.(find alternative)
          completedSteps={completedSteps}
          onPartPricingLookup={async part => {
            return await globalOperationsService.getPricingAndInventoryForCustomPart(
              service,
              props.rawOperationDetails,
              state.vehicle,
              { commonConsumerId },
              part
            );
          }}
          // @required- - called used in Details.js, to compare before/after parts edited in parts modal, to show edit page for dirty check, total parts price changes
          getPartsCallback={
            stateService.operationSource === operationSources.GLOBALCATALOG
              ? getPartsCallbackGlobalRepair
              : getPartsCallback
          }
          getPurchaseOrderDetails={getPurchaseOrderDetails}
        />
      ),
      // @csr-logic: render csr components to edit based on Edit settings
      csrDetails: (
        <CSRDetails dealerCode={props.dealerCode} stateService={stateService} />
      ),
      notes:
        !isEmpty(stateService) && appType !== appTypes.CSR ? (
          <ConcernNotes localeStrings={localeStrings} />
        ) : null,
      dealershipNotes:
        !isEmpty(stateService) && appType !== appTypes.CSR ? (
          <InternalNotes
            dealershipNotes={get(stateService, "dealershipNotes", null)}
            dispatchDealershipNotes={dispatchDealershipNotes}
          />
        ) : null
    };
    return Object.values(
      Object.fromEntries(
        Object.entries(compMap).filter(([key]) => sections[key])
      )
    );
  };
  const wrapper = elements => {
    // wrapOnAccordion = true - render global ops service only
    if (!wrapOnAccordion) {
      return (
        <div className="edit-detail-section-wrapper">
          <span className="edit-global-repair-header">Details</span>
          {elements}
        </div>
      );
    }
    return (
      <AccordionGroup.Container htmlId="detailsComponent" independent>
        <AccordionGroup.Item
          key="3"
          header="Details"
          htmlId="details"
          onToggle={() => {}}
          {...expandedStatus}
        >
          <div className="edit-detail-section-wrapper no-border">
            {serviceOptionSelected ? elements : null}
          </div>
        </AccordionGroup.Item>
      </AccordionGroup.Container>
    );
  };
  return (
    <>
      {wrapper(
        getComponentStack(sections).map((section, index) => {
          if (!section) {
            return null;
          }
          return (
            <div className="details-section" key={`section-${index + 1}`}>
              {section}
            </div>
          );
        })
      )}
    </>
  );
};

Details.defaultProps = {
  axiosInstance: null,
  service: null,
  rawOperationDetails: null,
  payTypes: null,
  partsPricingAndInventory: null,
  sections: {},
  wrapOnAccordion: false,
  dealerCode: null
};

Details.propTypes = {
  service: PropTypes.object,
  sections: PropTypes.object,
  payTypes: PropTypes.array,
  partsPricingAndInventory: PropTypes.array,
  // @note: true - global catalog case
  wrapOnAccordion: PropTypes.bool,
  axiosInstance: PropTypes.object,
  rawOperationDetails: PropTypes.object,
  onChangePaytype: PropTypes.func,
  onChangeServiceType: PropTypes.func,
  onChangeServiceContract: PropTypes.func,
  dealerCode: PropTypes.string
};

export default Details;
