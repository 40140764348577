/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-prop-types */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmptyString, toEmptyStringIfUndefined } from "../../utils/string";
import {
  isAllDigits,
  isFloatingNumberWithTenths,
  isFloatingNumberWithHundredths,
  isCopyAndPaste
} from "../../utils/value";

const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
const KEY_F2 = 113;

export default class NumericEditor extends Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();

    this.cancelBeforeStart =
      this.props.charPress && "1234567890".indexOf(this.props.charPress) < 0;

    this.state = this.createInitialState(props);

    this.onKeyDown = this.onKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.inputRef.current.addEventListener("keydown", this.onKeyDown);
    this.inputRef.current.addEventListener("focusout", () => {
      this.onStopEditing();
    });
  }

  componentWillUnmount() {
    this.inputRef.current.removeEventListener("keydown", this.onKeyDown);
    // @note: special case: if editor to callback parent (grid) handlers
    const { parentHandle } = this.props;
    if (parentHandle) {
      parentHandle.handleSelectionChanged();
    }
    this.inputRef.current.removeEventListener("focusout", () => {
      this.onStopEditing();
    });
  }

  createInitialState(props) {
    let startValue;
    let highlightAllOnFocus = true;
    const { allowDecimals, decimalPlaces, maxLength, maxValue } = props;

    if (props.keyPress === KEY_BACKSPACE || props.keyPress === KEY_DELETE) {
      // if backspace or delete pressed, we clear the cell
      startValue = "";
    } else if (props.charPress) {
      // if a letter was pressed, we start with the letter
      startValue = props.charPress;
      highlightAllOnFocus = false;
    } else {
      // otherwise we start with the current value
      startValue = props.value;
      if (props.keyPress === KEY_F2) {
        highlightAllOnFocus = false;
      }
    }
    const localState = {
      allowDecimals,
      decimalPlaces,
      maxLength,
      maxValue,
      value: toEmptyStringIfUndefined(startValue),
      highlightAllOnFocus
    };

    return localState;
  }

  afterGuiAttached() {
    // get ref from React component
    const eInput = this.inputRef.current;
    eInput.focus();
    if (this.state.highlightAllOnFocus) {
      eInput.select();

      this.setState({
        highlightAllOnFocus: false
      });
    } else {
      // when we started editing, we want the carot at the end, not the start.
      // this comes into play in two scenarios: a) when user hits F2 and b)
      // when user hits a printable character, then on IE (and only IE) the carot
      // was placed after the first character, thus 'apply' would end up as 'pplea'
      const length = eInput.value ? eInput.value.length : 0;
      if (length > 0) {
        eInput.setSelectionRange(length, length);
      }
    }
  }

  getValue() {
    return this.state.value;
  }

  isCancelBeforeStart() {
    return this.cancelBeforeStart;
  }

  // will reject the number if it greater than 1,000,000
  // not very practical, but demonstrates the method.
  isCancelAfterEnd() {
    return this.state.value > 1000000;
  }

  onKeyDown(event) {
    if (this.isLeftOrRight(event) || this.deleteOrBackspace(event)) {
      event.stopPropagation();
      return false;
    }
    const { allowDecimals } = this.state;
    if (allowDecimals) {
      // if (!this.isKeyPressedNumeric(event) && !isCopyAndPaste(event)) {
      //   if (event.preventDefault) {
      //     event.preventDefault();
      //     return false;
      //   }
      // }
    } else {
      if (!this.isKeyPressedNumeric(event) && !isCopyAndPaste(event)) {
        if (event.preventDefault) {
          event.preventDefault();
          return false;
        }
      }
    }
    return true;
  }

  onStopEditing() {
    this.props.stopEditing();
  }

  isLeftOrRight(event) {
    return [37, 39].indexOf(event.keyCode) > -1;
  }

  handleChange(event) {
    // prevent copy and paste of non-numeric character
    const { value } = event.target;
    const { allowDecimals, decimalPlaces } = this.state;
    if (
      isEmptyString(value) ||
      isAllDigits(value) ||
      (allowDecimals &&
        ((decimalPlaces == 1 && isFloatingNumberWithTenths(value)) ||
          (decimalPlaces == 2 && isFloatingNumberWithHundredths(value))))
    ) {
      const { maxValue } = this.state;
      if (!maxValue || value <= maxValue) {
        this.setState({ value });
      }
    }
  }

  getCharCodeFromEvent(event) {
    // eslint-disable-next-line no-param-reassign
    event = event || window.event;
    return typeof event.which === "undefined" ? event.keyCode : event.which;
  }

  isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
  }

  isKeyPressedNumeric(event) {
    const charCode = this.getCharCodeFromEvent(event);
    const charStr = event.key ? event.key : String.fromCharCode(charCode);
    return this.isCharNumeric(charStr);
  }

  deleteOrBackspace(event) {
    return [KEY_DELETE, KEY_BACKSPACE].indexOf(event.keyCode) > -1;
  }

  render() {
    const { maxLength, value } = this.state;
    return (
      <input
        ref={this.inputRef}
        maxLength={maxLength}
        value={value}
        onChange={this.handleChange}
        className="xmm-cell-editor"
      />
    );
  }
}

NumericEditor.propTypes = {
  parentHandle: PropTypes.object,
  maxLength: PropTypes.number,
  maxValue: PropTypes.number,
  allowDecimals: PropTypes.bool,
  decimalPlaces: PropTypes.number
};

NumericEditor.defaultProps = {
  maxLength: -1,
  allowDecimals: false,
  decimalPlaces: 1
};
