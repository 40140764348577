import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import findIndex from "lodash/findIndex";
import isEmpty from "lodash/isEmpty";
import IconArrowUpward from "@cx/ui/Icons/IconArrowUpward";
import { useNewQuoteContext, Actions } from "../../../state/NewQuoteContext";
import menuServiceDataMapper from "../menu-service-data-mapper";
import { AppContext } from "../../../state/app-context";
import ConfirmPopup from "../../ui/modals/ConfirmPopup";
import { asyncHandleGetPartsInventoryCommon } from "../services/parts.service";

const EditQuoteMenuServicePage = props => {
  const appConfig = useContext(AppContext);
  const { dispatch, state: newQuoteState } = useNewQuoteContext();
  const { partsPricingAndInventory, vehicle, debugMode, payTypes } =
    newQuoteState;
  const {
    localeStrings,
    appType,
    user: { userName }
  } = appConfig;
  const {
    service,
    originalService,
    EditServiceModule,
    selectedMenuPackage,
    onBackToPackage,
    onServiceChange
  } = props;

  const [showDiscardEditPopup, setShowDiscardEditPopup] = useState(false);
  const [serviceHasChanged, setServiceHasChanged] = useState(false);

  const handleSave = updatedMenuService => {
    const newService = menuServiceDataMapper.updateMenuService(
      service,
      updatedMenuService
    );
    const selectedServiceIndex = findIndex(
      selectedMenuPackage.services,
      item => item.id === service.id
    );
    onServiceChange(newService, selectedServiceIndex);
    onBackToPackage(false);
  };

  const handleCancel = () => {
    if (serviceHasChanged) {
      setShowDiscardEditPopup(true);
    } else {
      onBackToPackage(false);
    }
  };

  const handleServiceChange = serviceChanged => {
    setServiceHasChanged(serviceChanged);
  };
  // @placeholder: Menu Packages services do not allow PayType change
  const asyncUpdatePartsByPaytype = (/* paytype, editedService */) => {
    // service.payTypeCode = paytype;
    // ctxRequestPartsInventoryCommon(service, editedService);
  };

  const clonedEditServiceModule = !isEmpty(service)
    ? React.cloneElement(EditServiceModule, {
        debugMode,
        appType,
        vehicle,
        rawOperationDetails:
          menuServiceDataMapper.updateRawOperationFromMenuService(service),
        service: menuServiceDataMapper.editModulePropsFromMenuService(
          service,
          originalService
        ),
        localeStrings,
        username: userName,
        payTypes,
        ...(!props.serviceHasBeenEdited && { partsPricingAndInventory }),
        onCancelHandler: handleCancel,
        onSaveHandler: handleSave,
        onSaveAnotherHandler: () => {},
        onChangePaytype: asyncUpdatePartsByPaytype,
        onServiceChange: handleServiceChange
      })
    : null;

  useEffect(() => {
    async function loadParts() {
      const partsPricingAndInventory = await asyncHandleGetPartsInventoryCommon(
        { ...service, parts: service.part },
        selectedMenuPackage.payTypeCode,
        vehicle
      );
      dispatch({
        type: Actions.SET_PARTS_PRICING_INVENTORY_FOR_EDIT_QUOTE_SERVICE,
        payload: partsPricingAndInventory
      });
    }
    loadParts();
  }, []);

  return (
    <>
      <div id="editServiceContainer">
        <span
          className="back-nav-label search-back-sticky"
          onClick={handleCancel}
        >
          <IconArrowUpward
            htmlId="backArrowIcon"
            isActive={false}
            className="back-arrow"
          />
          {localeStrings["sq.search.menu.edit_back_review_package_details_lbl"]}
        </span>
      </div>
      {clonedEditServiceModule}
      <ConfirmPopup
        title={localeStrings["sq.search.common.alert_lbl"]}
        message={localeStrings["sq.search.common.leaving_menu_package_edit"]}
        show={showDiscardEditPopup}
        okText={localeStrings["sq.search.common.proceed_button"]}
        cancelText={localeStrings["sq.search.common.cancel_button"]}
        okAction={() => onBackToPackage(false)}
        cancelAction={() => setShowDiscardEditPopup(false)}
        buttonStyle="danger"
      />
    </>
  );
};

export default EditQuoteMenuServicePage;

EditQuoteMenuServicePage.defaultProps = {
  service: null,
  EditServiceModule: null,
  selectedMenuPackage: null,
  username: "",
  onBackToPackage: () => {},
  onServiceChange: () => {}
};
EditQuoteMenuServicePage.propTypes = {
  service: PropTypes.object.isRequired,
  EditServiceModule: PropTypes.element,
  selectedMenuPackage: PropTypes.object.isRequired,
  username: PropTypes.string.isRequired,
  onBackToPackage: PropTypes.func.isRequired,
  onServiceChange: PropTypes.func.isRequired
};
