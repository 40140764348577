import React, { useContext } from "react";
import PropTypes from "prop-types";
import Button from "@cx/ui/Button";
import Tooltip from "@cx/ui/Tooltip";
import Dropdown from "@cx/ui/Dropdown";
import { AppContext } from "../../../state/app-context";
import { useNewQuoteContext } from "../../../state/NewQuoteContext";
import {
  ADD_CATALOG_LINE_DISCOUNT,
  ADD_CATALOG_LINE_FEE,
  LINE_SUBLET
} from "../../repair-order/constants/adjustment.constant";
import "./edit-button-dropdown.scss";

const EditButtonDropdown = props => {
  const {
    editAction,
    removeAction,
    priceAdjustmentAction,
    userPermissions,
    isMenuService,
    isDisabled,
    isServiceLineCompletedForMenu
  } = props;

  const appContext = useContext(AppContext);
  const { localeStrings, appType, dealerProperties } = appContext;
  const { state } = useNewQuoteContext();
  const { quoteSummary } = state;
  const isCSR = appType === "CSR";

  const canSeePriceAdjustmentOptions = isCSR
    ? userPermissions.canViewFeesDiscountsSublets
    : !isMenuService;

  const getDropdownOptions = () => {
    const options = [];

    if (canSeePriceAdjustmentOptions) {
      options.push({
        label: "Add discount",
        onSelect: () => priceAdjustmentAction(ADD_CATALOG_LINE_DISCOUNT, false),
        value: ADD_CATALOG_LINE_DISCOUNT
      });
      options.push({
        label: "Add fees",
        onSelect: () => priceAdjustmentAction(ADD_CATALOG_LINE_FEE, false),
        value: ADD_CATALOG_LINE_FEE
      });
      const isSubletsPropertyEnabled =
        dealerProperties?.CSR_SUBLET_FORM_ENABLED === "Y";
      if (isCSR || (!isCSR && isSubletsPropertyEnabled)) {
        options.push({
          label: isSubletsPropertyEnabled ? "Add sublet" : "Sublet",
          onSelect: () => priceAdjustmentAction(LINE_SUBLET, false),
          value: LINE_SUBLET
        });
      }
    }

    // hide remove action when only one service line or if menu pkg line completed in RO
    const hasMultipleServicesInRO =
      isCSR && quoteSummary?.quoteServices?.length > 1;
    const addRemoveItemForCSR =
      hasMultipleServicesInRO &&
      !isServiceLineCompletedForMenu &&
      userPermissions.canRemoveService;
    if (!isCSR || addRemoveItemForCSR) {
      options.push({
        label: localeStrings["sq.common.remove_button"],
        onSelect: removeAction,
        value: "REMOVE"
      });
    }

    return options;
  };

  const dropdownOptions = getDropdownOptions();

  if (dropdownOptions.length) {
    return (
      <div className="edit-button-action-menu">
        <Button
          size="sm"
          className="edit-button"
          buttonStyle="secondary"
          htmlId="btnGroupSecondary"
          disabled={isDisabled}
          onClick={editAction}
        >
          {localeStrings["sq.common.edit_button"]}
        </Button>
        <Tooltip
          htmlId="Action Tooltip"
          tooltipContent={"This Service cannot be edited as it's closed."}
          position="top"
          className={isCSR && isDisabled ? "xmm-tooltip-delay" : "hide"}
        >
          <Dropdown
            pullRight
            size="sm"
            className="edit-button--dropdown"
            buttonStyle="link"
            disabled={isDisabled}
            htmlId="dropdownLinkButton"
            name="dropdownLinkButton"
            options={getDropdownOptions()}
          />
        </Tooltip>
      </div>
    );
  } else {
    return (
      <Button
        size="sm"
        buttonStyle="secondary"
        htmlId="btnGroupSecondary"
        disabled={isDisabled}
        onClick={editAction}
      >
        {localeStrings["sq.common.edit_button"]}
      </Button>
    );
  }
};

export default EditButtonDropdown;

EditButtonDropdown.defaultProps = {
  // handlers
  editAction: () => {},
  removeAction: () => {},
  priceAdjustmentAction: () => {},
  // data
  userPermissions: {},
  isMenuService: false,
  isDisabled: false,
  isServiceLineCompletedForMenu: false
};

EditButtonDropdown.propTypes = {
  // handlers
  editAction: PropTypes.func.isRequired,
  removeAction: PropTypes.func.isRequired,
  priceAdjustmentAction: PropTypes.func.isRequired,
  // data
  userPermissions: PropTypes.object,
  isMenuService: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isServiceLineCompletedForMenu: PropTypes.bool
};
