/* This objects are freezed, used for cell renderer  */
export const UnitOfMeasureMap = {
  Gallons: "gal",
  Grams: "g",
  Inches: "in",
  Liters: "l",
  Milliliters: "ml",
  Millimeters: "mm",
  Ounces: "oz",
  Pints: "pt",
  Quarts: "qt"
};

export const IncludedInspectionTags = [
  "inspect",
  "disclaimer",
  "lubricate",
  "reset",
  "tighten",
  "test"
];

export const GlobalOpsServiceType = {
  GLOBALCATALOG: "GlobalCatalog",
  DEALERCATALOG: "DealerPublishedCatalog",
  DIAGNOSIS: "Diagnose",
  DECLINED: "declined",
  AUTORECALL: "autorecall"
};

export const ServiceKindMap = {
  REPAIR: "repair",
  MAINTENANCE: "maintenance",
  RECALL: "recall",
  DECLINED: "declined"
};

export const DealerPublishedCategory = {
  REPAIR: "repair",
  MAINTENANCE: "maintenance"
};

export const saveActionTypes = {
  NA: null,
  SAVE: "SAVE",
  SAVEANOTHER: "SAVEANOTHER"
};

export const QuoteServiceTypeMap = {
  DEALER_PUB_MAINT_OPS: "DEALER_PUB_MAINT_OPS",
  DEALER_PUB_REPAIR_OPS: "DEALER_PUB_REPAIR_OPS",
  GLOBAL_REPAIR_OPS: "GLOBAL_REPAIR_OPS",
  MENU: "MENU",
  DECLINED: "DECLINED",
  RECALL: "RECALL"
};
// IMPORTANT: This template object used in service data mapper and used in editService context
export const ServiceDataModel = {
  quoteServiceId: null,
  quoteId: null,
  name: "",
  totalPrice: 0,
  // For MENU, declined, recall cases - laborAppId = null
  // For dealer-pub case - laborAppId updated from laborApps[0].laborAppId; laborAppId is same as operationId, extServiceId
  // For global ops case - state.laborApp gets updated, read laborAppId passed in quote payload
  laborAppId: null,
  labor: {
    serviceId: "", // all cases - this value should be service.id/operationId/extServiceId
    notes: "", // dont bind this field with catalog API; always read modified value from UI field {notes}
    price: 0,
    time: 0
  },
  laborTaxCode: null,
  // required for menus and all services
  defaultLaborRate: 0,
  laborRate: 0,
  dealerLaborRateId: null, // This value returned in getOperationDetails API for dealer publish, global repair, menu cases only
  asrNotes: "", // asrNotes value exists for declined service only
  dealershipNotes: "", // Expected always string value for all services and menu services in all data mapper files
  commentsRequired: false,
  opCode: "",
  subTypeId: null,
  allocationSubTypeId: null,
  internalAccount: null,
  description: "",
  operationSource: "",
  serviceKind: "",
  defaultPayTypeCode: "", // this is used to identify default paytype in Detail.js
  defaultServiceTypeCode: "", // this is used to identify default serviceType in Detail.js
  skillLevel: "",
  make: "",
  payTypeGroup: "",
  payTypeCode: "",
  payTypeDescription: "",
  serviceTypeCode: "",
  serviceContract: null,
  parts: [],
  // For search flow, when only partsPriceOverridden = true, totalPartsPriceOverride saved in partsPrice field;
  // case2: when totalPriceOverridden = true & partsPriceOverridden = true; calculatedTotalPartsPrice saved in partsPrice field;
  // For summary flow, update partsPrice field using "finalPartsPrice" value coming in get quote API
  partsPrice: 0, // FIX - NEVER UPDATE this field in context actions; treat {partsPrice} as source field of catalogAPI holds original parts price
  totalPriceOverride: null,
  totalLaborPriceOverride: null,
  totalPartsPriceOverride: null,

  calculatedLaborPrice: null, // dervied field only used within context service
  calculatedTotalPartsPrice: null,
  calculatedServicePrice: null,

  finalLaborPrice: null,
  // FIX - new field {final parts price} updated from context when any field is modified in edit service page
  finalPartsPrice: null,
  // @todo-edit: price overridden flags added to context
  laborPriceOverridden: false,
  partsPriceOverridden: false,
  totalPriceOverridden: false,
  cause: null,
  complaint: null,
  correction: null,
  warranty: null
};

export const QuoteServicePayloadTemplate = {
  operationSource: "",
  quoteServiceType: "",
  serviceName: "",
  serviceCategory: "",
  serviceCategoryId: null,
  shopDuration: 0,
  serviceKind: "",
  extServiceId: null,
  servicePrice: null,
  dmsOpcode: "",
  subTypeId: null,
  allocationSubTypeId: null,
  internalAccount: null,
  quoteRawService: {
    rawService: ""
  },
  parts: [],
  labor: {
    quoteServiceLaborId: null,
    extLaborId: null,
    description: "",
    laborPrice: 0,
    defaultLaborRate: 0,
    laborTime: 0
  },
  totalLaborPriceOverride: null,
  totalPartsPriceOverride: null,
  totalPriceOverride: null,
  calculatedTotalPartsPrice: 0,
  calculatedServicePrice: 0,
  finalLaborPrice: 0,
  finalPartsPrice: 0,
  commentsRequired: false,
  waiterAllowed: false,
  loanerAllowed: false,
  payTypeGroup: "",
  payTypeCode: "",
  payTypeDescription: ""
};

export const QuoteServicePartsTemplate = {
  parts: [
    {
      extPartId: "",
      description: "",
      unitPrice: 0,
      partPrice: 0,
      partType: "",
      adjustedQuantity: 0,
      dtDmsPartCode: "",
      position: null,
      oemPartNumber: "",
      manufacturer: "",
      notes: [],
      priceSource: ""
    }
  ]
};
