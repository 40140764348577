import Badge from "@cx/ui/Badge";
import React from "react";
import format from "../../utils/format";
import { PAYER_TYPES } from "../../constants/csr.constants";
import moment from "moment";

const PayTypesPaymentDisplayComponent = ({
  payerConfig,
  updatePayerChecked,
  quoteSummary
}) => {
  const getStatus = () => {
    let status = "Pending";
    let statusClass = "gray";

    if (payerConfig?.payer?.closedDate !== null) {
      return {
        status: `Closed - ${moment(payerConfig?.payer?.closedDate).format(
          "MM/DD/YYYY"
        )}`,
        statusClass: "purple"
      };
    }
    if (payerConfig?.payer?.payType === PAYER_TYPES.W) {
      const warrantyService = quoteSummary?.quoteServices?.find(
        service => service?.payType === PAYER_TYPES.W
      );
      if (warrantyService?.warranty?.warrantySubmissionDate !== null)
        status = `Submitted - ${moment(
          warrantyService?.warranty?.warrantySubmissionDate
        ).format("DD/MM")}`;
      statusClass = "green";
    }
    return { status, statusClass };
  };

  return (
    <div className="other-payType-container">
      <input
        data-testid="paytype-checkbox"
        type="checkbox"
        checked={payerConfig.isChecked}
        value={payerConfig?.payType}
        onChange={cxEvent => {
          updatePayerChecked(payerConfig?.payType, cxEvent.target.checked);
        }}
        id={`payerCheckbox${payerConfig.payType}`}
        name={`payerCheckbox${payerConfig?.payType}`}
        disabled={payerConfig?.isDisabled}
        title={payerConfig?.tooltip}
        hidden={payerConfig?.payer?.closedDate !== null}
      />
      <div className="other-payType-container-content">
        <span className="payType-text">
          {payerConfig?.isClosed
            ? payerConfig?.closedPaytypeText
            : payerConfig.checkboxLabel}
        </span>
        <div className="status-badge">
          <Badge htmlId="badgeDefault" color={getStatus().statusClass}>
            {getStatus().status}
          </Badge>
        </div>
        <span className="amount-text">
          {format.currency(payerConfig?.payer?.finalPrice)}
        </span>
      </div>
    </div>
  );
};

export default PayTypesPaymentDisplayComponent;
