/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import Button from "@cx/ui/Button";
import { useEditServiceContext } from "../../../EditServiceModule/state/edit-service.context";
import moment from "moment";
import {
  AccessTime,
  Pause,
  Play,
  Stop
} from "../../utils/add-technicians.utils";
import { convertMinutesToHM } from "../../utils/time";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import StartEndTime from "./start-end-time.component";
import { getTechStatusBadge } from "../../../utils/quote.util";

const ClockTimer = ({
  data,
  onPerformTechTimeActionHandler,
  loggedInUserDetails,
  service
}) => {
  const { state } = useEditServiceContext();
  const { userPermissions, quoteSummary } = state;
  const [localWorkDuration, setLocalWorkDuration] = useState(
    data?.workingDuration ? data?.workingDuration : 0
  );
  const [actionLoading, setActionLoading] = useState(false);

  const calculateDifference = () => {
    // Get the current time
    const currentTime = moment();
    const startTime = moment(
      data?.techPeriods[data?.techPeriods.length - 1]?.startTime
    );
    // Calculate the difference in minutes
    const differenceInMinutes = currentTime.diff(startTime, "minutes");
    setLocalWorkDuration(
      differenceInMinutes + (data?.workingDuration ? data?.workingDuration : 0)
    );
  };

  useEffect(() => {
    if (data?.status === "WORKING") {
      calculateDifference();
    }
    const intervalId = setInterval(() => {
      if (data?.status === "WORKING") calculateDifference();
    }, 60000);

    return () => {
      if (intervalId) clearInterval(intervalId);
      console.log("Interval cleared on component unmount or reload.");
    };
  }, [data]);

  const techTimeActionHandler = async action => {
    try {
      if (data?.techTimeId) setActionLoading(true);
      await onPerformTechTimeActionHandler(action, data);
    } catch (e) {
      console.log(e, "error");
    } finally {
      setActionLoading(false); // Hide loader after asyncFunction completes or on error
    }
  };

  const playButtonDisabled = () => {
    if (!data?.techName?.techId) return true;
    if (!data?.flagTimeEnd) return true;
    if (service?.payType === "W" && data?.correctionCode === null) return true;
    if (data?.workStarted) return true;
    return false;
  };

  const techTimeStatus = useMemo(() => {
    return getTechStatusBadge(data, service, quoteSummary);
  }, [data]);

  const isOwnTechRow = loggedInUserDetails?.userId === data?.techName?.techId;
  const canSeeClockButtons =
    !data?.workEnded &&
    (userPermissions.canRunAnyTechnicianClock ||
      (userPermissions.canRunOwnTechnicianClock && isOwnTechRow));

  return (
    <div className="clock-timer">
      {actionLoading ? (
        <LoadingIndicator
          htmlId="clockTimerLoader"
          size="small"
          className="clock-timer-loader"
        />
      ) : (
        <>
          <div className="clock-timer-row">
            <span>
              <Button
                aria-label="access time icon"
                buttonStyle="link"
                htmlId="btnGroupIcon"
                icon={<AccessTime />}
                size="sm"
                disabled={true}
                className="tech-actions btn-access-time"
              />
            </span>
            <div className="clock-body">
              <div>
                <span className="clock-timer-view">
                  {convertMinutesToHM(localWorkDuration)}
                </span>
                {techTimeStatus}
              </div>
              <div className="clock-interval-view">
                {data?.workStarted ? (
                  <StartEndTime
                    data={data}
                    localWorkDuration={localWorkDuration}
                  />
                ) : null}
              </div>
            </div>
          </div>
          {canSeeClockButtons ? (
            <div>
              <Button
                aria-label="play button"
                buttonStyle="secondary"
                htmlId="btnGroupIcon"
                icon={<Play />}
                size="sm"
                disabled={playButtonDisabled()}
                className="tech-actions tech-play-btn"
                onClick={() => techTimeActionHandler("START")}
              />
              <Button
                aria-label="pause button"
                buttonStyle="secondary"
                htmlId="btnGroupIcon"
                icon={<Pause />}
                size="sm"
                className="tech-actions tech-pause-btn"
                disabled={
                  loggedInUserDetails?.userId !== data?.techName?.techId ||
                  data?.workEnded ||
                  !data?.workStarted
                }
                hidden={true} //* NOTE: this feature is descoped as of now
              />
              <Button
                aria-label="stop button"
                buttonStyle="secondary"
                htmlId="btnGroupIcon"
                icon={<Stop />}
                size="sm"
                className="tech-actions tech-stop-btn"
                onClick={() => techTimeActionHandler("STOP")}
                disabled={data?.status !== "WORKING"}
              />
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default ClockTimer;
