import React from "react";
import { PAYER_TYPES } from "../../constants/csr.constants";
import CustomerPaymentComponent from "./customer-pay-payment.component";
import PayTypesPaymentDisplayComponent from "./payTypes-payment-display.component";

const SplitPaymentComponent = ({
  payerConfigs,
  updatePayerChecked,
  chargeAccountDetails,
  updateCustomerPayerChecked,
  legitPayers,
  quoteSummary,
  deletePaymentLines
}) => {
  const customerPayPayerConfig = payerConfigs.find(
    config => config.payType === PAYER_TYPES.C
  );
  return (
    <>
      <CustomerPaymentComponent
        payerConfig={customerPayPayerConfig}
        updatePayerChecked={updatePayerChecked}
        chargeAccountDetails={chargeAccountDetails}
        updateCustomerPayerChecked={updateCustomerPayerChecked}
        legitPayers={legitPayers}
        deletePaymentLines={deletePaymentLines}
      />
      {payerConfigs
        ?.filter(pc => pc.payType !== PAYER_TYPES.C && pc.show)
        ?.map(payerConfig => {
          return (
            <React.Fragment key={payerConfig.payType}>
              <hr className="spit-payment-horizontal-divider" />
              <PayTypesPaymentDisplayComponent
                payerConfig={payerConfig}
                payType={payerConfig.payType}
                updatePayerChecked={updatePayerChecked}
                quoteSummary={quoteSummary}
              />
            </React.Fragment>
          );
        })}
      <hr className="spit-payment-horizontal-divider" />
    </>
  );
};

export default SplitPaymentComponent;
