import React, { useContext, useState } from "react";
import IconArrowUpward from "@cx/ui/Icons/IconArrowUpward";
import isEmpty from "lodash/isEmpty";
import { compare } from "fast-json-patch";
import { useNewQuoteContext, Actions } from "../../../state/NewQuoteContext";
import { AppContext } from "../../../state/app-context";
import useComponentDidMount from "../../../hooks/useComponentDidMount";
// @import custom components
import ConfirmPopup from "../../ui/modals/ConfirmPopup";
import EditServiceHOC from "./EditServiceHOC";
import updateQuoteDataMapper from "../utils/update-quote-data-mapper";
// @import services
import quoteService from "../../quote-summary/services/edit-quote.service";
import modifyPartsService from "../../quote-summary/services/modify-parts.service";
import { fixCSR, loadQuote } from "../services/quote-api.service";
import csrService from "../../quote-summary/services/csr.service";
// @import utils
import summaryServiceFormat from "../utils/summary-service-format";
import {
  OperationSources,
  DealerPublishedCategory
} from "../constants/page-wrapper.constants";
import {
  SERVICE_SUMMARY,
  GlobalOpsServiceType
} from "../../../constants/pages.constants";

const EditServiceWrapper = () => {
  const appContext = useContext(AppContext);
  const { dispatch, state } = useNewQuoteContext();
  const [showDiscardEditPopup, setShowDiscardEditPopup] = useState(false);
  const [serviceHasChanged, setServiceHasChanged] = useState(false);
  const { localeStrings, appType } = appContext;
  const isCSR = appType === "CSR";
  const { vehicle, originalQuoteSummary, quoteSummary, currentEditingService } =
    state;
  const [formattedRawOperationDetails, setFormattedRawOpsDetails] =
    useState(null);

  const backToSummary = () => {
    dispatch({
      type: Actions.SET_CURRENT_PAGE,
      payload: SERVICE_SUMMARY
    });
    if (isCSR) {
      reloadQuote();
    }
  };

  const reloadQuote = async () => {
    showPageLoading(true);
    const updatedQuote = await loadQuote({
      confirmationId: quoteSummary.confirmationId,
      localeStrings: appContext.localeStrings,
      dealerCode: appContext.dealer.dealerCode,
      appContext
    });
    dispatch({
      type: Actions.UPDATE_QUOTE,
      payload: updatedQuote
    });
    showPageLoading(false);
  };

  const handleCancel = () => {
    if (serviceHasChanged) {
      setShowDiscardEditPopup(true);
      return;
    }
    if (!serviceHasChanged && !isEmpty(vehicle)) {
      dispatch({
        type: Actions.SET_CURRENT_PAGE,
        payload: SERVICE_SUMMARY
      });
    }
  };
  // @todo-edit: data mapper returns updated service object based on operationSource & serviceKind
  const saveType = (currentEditingService, editedService) => {
    const { operationSource, serviceKind, quoteServiceType } =
      currentEditingService;
    // below fields are type-agnostic
    currentEditingService.subTypeId =
      editedService.subTypeId ?? currentEditingService.subTypeId;
    currentEditingService.allocationSubTypeId =
      editedService.allocationSubTypeId ??
      currentEditingService.allocationSubTypeId;
    currentEditingService.internalAccount =
      editedService.internalAccount ?? currentEditingService.internalAccount;
    // below fields are considered for editing global ops service
    if (operationSource !== GlobalOpsServiceType.GLOBALCATALOG) {
      delete editedService.filteredParts;
      delete editedService.laborApp;
      delete editedService.vehicleAttributes;
    }
    console.log(
      "saveType - quote API service / editcontext service values ",
      currentEditingService,
      editedService
    );
    switch (true) {
      case operationSource === OperationSources.GLOBALCATALOG &&
        serviceKind === DealerPublishedCategory.REPAIR:
        return updateQuoteDataMapper.updateQuoteService(
          currentEditingService,
          editedService
        );
      case operationSource === OperationSources.DEALERCATALOG &&
        (serviceKind === DealerPublishedCategory.REPAIR ||
          serviceKind === DealerPublishedCategory.MAINTENANCE):
        return updateQuoteDataMapper.updateQuoteService(
          currentEditingService,
          editedService
        );
      case quoteServiceType === OperationSources.RECALL:
        return updateQuoteDataMapper.updateQuoteService(
          currentEditingService,
          editedService
        );
      case quoteServiceType === OperationSources.DECLINED:
        return updateQuoteDataMapper.updateQuoteService(
          currentEditingService,
          editedService
        );
      default:
        throw new Error(
          `Invalid operationSource: ${operationSource} or serviceKind: ${serviceKind}`
        );
    }
  };
  // Local handler to enable masking effect by updating newQuoteContext state prop
  const showPageLoading = showPageMask => {
    dispatch({
      type: Actions.SET_PAGE_MASK,
      payload: showPageMask
    });
  };
  // Patches only the changes made to the current edited service.
  const patchService = async quotePayload => {
    const { quoteServiceId } = currentEditingService;
    const originalService = originalQuoteSummary.quoteServices.find(
      s => s.quoteServiceId === quoteServiceId
    );
    const modifiedService = quotePayload.quoteServices.find(
      s => s.quoteServiceId === quoteServiceId
    );
    const diffOps = compare(originalService, modifiedService).filter(
      operation => {
        return !(
          operation.op === "remove" &&
          operation.path === "/warranty" &&
          !originalService.warranty
        );
      }
    );
    return await csrService.jsonPatchService(
      appContext,
      quotePayload,
      modifiedService,
      diffOps
    );
  };
  // @todo-edit: update quote service with latest values before passing in payload
  const handleServiceUpdate = async editedService => {
    const selectedService = saveType(currentEditingService, editedService);
    const { quoteUserId } = appContext.user;

    const updateQuotePayload = {
      quoteUserId,
      quoteSummary,
      selectedService
    };
    let response;
    showPageLoading(true);
    try {
      const quoteStatusBefore = quoteSummary.quoteStatus;
      const quotePayload = quoteService.getPayload(
        currentEditingService.quoteServiceType
      )(updateQuotePayload);
      if (!isEmpty(quotePayload)) {
        // Quoting forces the quote status to IN_PROGRESS whenever a service is edited.
        // We need to avoid that change for a CSR.
        if (isCSR) {
          quotePayload.quoteStatus = quoteStatusBefore;
          dispatch({
            type: Actions.SET_PAGE_MASK,
            payload: true
          });
        }
        // @csr-logic - for removing warranty details if change paytype from warranty to other
        let deleteWarrantyResponse = null;
        if (
          selectedService?.payTypeCode !== "W" &&
          currentEditingService?.payTypeCode === "W" &&
          appContext?.appType === "CSR" &&
          selectedService?.warranty?.warrantyId
        ) {
          try {
            deleteWarrantyResponse = await csrService.deleteWarrantyToService({
              appContext,
              selectedService
            });
          } catch (e) {
            console.log("error", e);
            dispatch({
              type: Actions.SET_PAGE_MASK,
              payload: false
            });
          }
        }

        // @csr-logic -  For updating warranty details if payType = Warranty
        let warrantyUpdateResponse = null;
        if (
          appContext?.appType === "CSR" &&
          selectedService?.payTypeCode === "W" &&
          selectedService?.warranty?.warrantyId
        ) {
          try {
            const warrantyDetailsPayload = selectedService?.warranty;
            warrantyUpdateResponse = await csrService.updateWarrantyToService({
              appContext,
              selectedService,
              warrantyDetailsPayload
            });
            console.log("warrantyResponse", warrantyUpdateResponse);
          } catch (error) {
            console.log("error", error);
            dispatch({
              type: Actions.SET_PAGE_MASK,
              payload: false
            });
          }
        }
        const saveQuoteChanges = async () => {
          // The currentEditingService still has the removed parts; the quotePayload service does not.
          const updatedPartsList = quotePayload.quoteServices.find(
            s => s.quoteServiceId === currentEditingService.quoteServiceId
          ).parts;
          const result = await modifyPartsService.deleteRemovedParts(
            appContext,
            updatedPartsList,
            currentEditingService,
            quoteSummary
          );
          if (appType === "CSR") {
            const unDeletedParts = result
              ?.filter(part => part?.success === false && part?.deletedPart)
              .map(({ deletedPart }) => deletedPart);
            if (unDeletedParts.length > 0)
              quotePayload?.quoteServices?.map(service => {
                if (
                  service?.quoteServiceId ===
                  currentEditingService?.quoteServiceId
                ) {
                  service.parts = service?.parts?.concat(unDeletedParts);
                  const calculateTotalPrice = parts => {
                    const totalPrice = parts.reduce((acc, next) => {
                      const currentPrice = !next.unitPrice
                        ? 0
                        : next.unitPrice * next.quantity;
                      return acc + currentPrice;
                    }, 0);
                    return totalPrice;
                  };

                  const calculatedTotalPartsPrice = calculateTotalPrice(
                    service.parts
                  );
                  service.calculatedTotalPartsPrice = calculatedTotalPartsPrice;
                  service.finalPartsPrice = calculatedTotalPartsPrice;
                  service.calculatedServicePrice =
                    service.calculatedTotalPartsPrice + service.finalLaborPrice;
                }
              });
            console.log(quotePayload);
          }
          // Test hook to use JSON PATCH instead of the regular PUT call to update a quote.
          if (document.location.href.includes("TEST_HOOK_JSON_PATCH=1")) {
            return await patchService(quotePayload);
          }
          return await quoteService.updateQuote(quotePayload);
        };
        // @csr-logic
        if (
          appContext?.appType === "CSR" &&
          currentEditingService?.payTypeCode === "W" &&
          selectedService?.warranty?.warrantyId
        ) {
          if (
            deleteWarrantyResponse !== null ||
            warrantyUpdateResponse !== null
          ) {
            response = await saveQuoteChanges();
          }
        } else {
          response = await saveQuoteChanges();
        }
        // @csr-logic -  adding warranty when payType = Warranty if service is intially added with some other payType
        if (
          appContext?.appType === "CSR" &&
          selectedService?.payTypeCode === "W" &&
          !selectedService?.warranty?.warrantyId &&
          response
        ) {
          try {
            const warrantyDetailsPayload = selectedService?.warranty;
            const warrantyResponse = await csrService.addWarrantyToService(
              appContext,
              selectedService,
              warrantyDetailsPayload
            );

            const serviceIndex = response?.quoteServices?.findIndex(
              service =>
                parseInt(service?.quoteServiceId, 10) ===
                selectedService?.quoteServiceId
            );

            response.quoteServices[serviceIndex] = {
              ...response?.quoteServices[serviceIndex],
              warranty: warrantyResponse
            };

            console.log("warrantyResponse", response);
          } catch (error) {
            console.log("error", error);
            dispatch({
              type: Actions.SET_PAGE_MASK,
              payload: false
            });
          }
        }
        if (appContext.appType === "CSR")
          dispatch({
            type: Actions.SET_PAGE_MASK,
            payload: false
          });
      } else {
        // @note: edge case when payload is null
        response = quoteSummary;
      }
      fixCSR(response);
      if (appType !== "CSR") {
        dispatch({
          type: Actions.UPDATE_QUOTE,
          payload: response
        });
        dispatch({
          type: Actions.SET_CURRENT_PAGE,
          payload: SERVICE_SUMMARY
        });
      } else {
        if (response && appType === "CSR") {
          dispatch({
            type: Actions.UPDATE_QUOTE,
            payload: response
          });
          dispatch({
            type: Actions.SET_CURRENT_PAGE,
            payload: SERVICE_SUMMARY
          });
        }
      }
    } catch (error) {
      const msg = error["message"]
        ? error.message
        : localeStrings["sq.errors.network.error"];
      console.error(msg);
    } finally {
      showPageLoading(false);
    }
  };
  const handleServiceChange = serviceChanged => {
    setServiceHasChanged(serviceChanged);
  };

  useComponentDidMount(() => {
    // @todo-edit: parse json string from quote
    if (!isEmpty(currentEditingService)) {
      // call this for global ops, dealer pub, diagnosis service extraction only
      let rawOpsDetails = null;
      switch (currentEditingService.quoteServiceType) {
        case OperationSources.RECALL:
          rawOpsDetails = summaryServiceFormat.extractRawRecallService(
            currentEditingService
          );
          break;
        case OperationSources.DECLINED:
          rawOpsDetails = summaryServiceFormat.extractRawDeclinedService(
            currentEditingService
          );
          break;
        default:
          rawOpsDetails =
            summaryServiceFormat.formatServiceToRawOperationDetails(
              currentEditingService
            );
          break;
      }
      setFormattedRawOpsDetails(rawOpsDetails);
      console.log(
        "editServiceWrapper didmount> rawOpsDetails/currentEditingService",
        rawOpsDetails,
        currentEditingService
      );
    }
  });

  return !isEmpty(formattedRawOperationDetails) &&
    !isEmpty(currentEditingService) ? (
    <div id="editTopService" className="search-flex-grid-container">
      <span
        className="back-nav-label search-back-sticky"
        onClick={handleCancel}
      >
        <IconArrowUpward
          htmlId="backArrowIcon"
          isActive={false}
          className="back-arrow"
        />
        Back
      </span>
      <div className="edit-page-wrapper">
        <EditServiceHOC
          currentEditingService={currentEditingService} // this prop converted into service format and updates service of editService context for summary flow
          rawOperationDetails={formattedRawOperationDetails} // always quoteService.rawService parsed into rest API response (unified or declined/recall/ menutype record)
          service={null} // always pass null for edit summary flow
          localeStrings={localeStrings}
          onCancelHandler={handleCancel}
          onSaveHandler={handleServiceUpdate}
          onSaveAnotherHandler={() => {}}
          onServiceChange={handleServiceChange}
        />
      </div>
      <ConfirmPopup
        title={localeStrings["sq.search.common.alert_lbl"]}
        message={localeStrings["sq.search.common.leaving_edit_page"]}
        show={showDiscardEditPopup}
        okText={localeStrings["sq.search.common.proceed_button"]}
        cancelText={localeStrings["sq.search.common.cancel_button"]}
        okAction={backToSummary}
        cancelAction={() => setShowDiscardEditPopup(false)}
        hideCancel={false}
        hideOk={false}
        buttonStyle="danger"
      />
    </div>
  ) : (
    <div>Loading ...</div>
  );
};

export default EditServiceWrapper;
