import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { AppContext } from "../../state/app-context";
import GenericSlider from "../ui/sliders/GenericSlider";
import "./quote-slider.scss";
import { useNewQuoteContext, Actions } from "../../state/NewQuoteContext";
import Quote from "../quote";
import ConfirmPopup from "../ui/modals/ConfirmPopup";
import { SERVICE_SUMMARY } from "../../constants/pages.constants";
import { isEmptyString } from "../../utils/string.util";
import isEmpty from "lodash/isEmpty";
import QuoteStatusConstant from "../../constants/quote-status.constants";

const QuoteSlider = props => {
  const appContext = useContext(AppContext);
  const { localeStrings, appType, userPermissions } = appContext;
  const { state, dispatch } = useNewQuoteContext();
  const { vehicle, quoteSummary, currentPage, toggleModal } = state;
  const { quoteStatus } = quoteSummary;
  const [showAlertPopup, setShowAlertPopup] = useState(false);
  const { title } = props;
  const isCSR = appType === "CSR";
  // @note: when slider is destroyed from DOM, we reset New Quote state
  useEffect(() => {
    return () => {
      dispatch({
        type: Actions.WIPE_STATE
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (toggleModal) {
      props.closeModal();
      dispatch({
        type: Actions.SET_TOGGLE_MODAL,
        payload: false
      });
    }
  }, [toggleModal]);
  /**
   *
   * @param {object} event
   * Create Quote - Show Speedbump when the user try to close Create Quote Slider (has at least 1 service added)
   * Edit Quote - Show speedbump when user do changes to quote (add/remove/edit services) and close slider
   */
  const closeSlider = event => {
    //* list of pages for skipping showing alert modal for CSR
    const csrSkipPages = [SERVICE_SUMMARY];
    if (event) {
      if (
        !isEmpty(userPermissions) &&
        !userPermissions.canEditQuote &&
        currentPage === SERVICE_SUMMARY
      ) {
        props.closeModal(event);
        return;
      }
      // Quoting cases:
      if (!isCSR) {
        if (
          quoteStatus === QuoteStatusConstant.EXPIRED ||
          quoteStatus === QuoteStatusConstant.CONVERTED_TO_APPOINTMENT ||
          quoteStatus === QuoteStatusConstant.SENT
        ) {
          props.closeModal(event);
          return;
        } else {
          // For all other quote status case
          if (
            !isEmptyString(quoteSummary.confirmationId) &&
            state?.isQuoteModified
          ) {
            setShowAlertPopup(true);
            return;
          }
        }
      }
      // csr-logic: Added condition for showing alert modal only on edit service page
      if (
        vehicle &&
        quoteSummary.quoteServices.length >= 0 &&
        isCSR &&
        !csrSkipPages.includes(currentPage)
      ) {
        setShowAlertPopup(true);
        return;
      }
      // @note: callback fun() of slider close action
      props.closeModal(event);
    }
  };
  // Speedbump validation text
  const getPopupMessage = () => {
    switch (true) {
      case !isEmptyString(quoteSummary.confirmationId):
        return "sq.newquote.location_bar.in-process_quote-status-restart_modal_message";
      default:
        return "sq.newquote.location_bar.empty-null_quote-status-restart_modal_message";
    }
  };
  const sliderTitle = !title ? (
    <span>{localeStrings["sq.nav.new_quote"]}</span>
  ) : (
    <span>{title}</span>
  );
  const sliderview = (
    <GenericSlider
      title={sliderTitle}
      htmlId="newQuoteSlider"
      toggleSlider={closeSlider}
      showSlide={props.showSlide}
      sliderWidth={props.sliderWidth}
      flexWidth={props.flexWidth}
      closeButton={false}
      expandSlider={true}
      isExpand
    >
      <div className={`slide-page-wrapper ${isCSR ? "mb-6rem" : ""}`}>
        <Quote module="SLIDER" />
      </div>
    </GenericSlider>
  );
  // @csr-logic
  const popupMessage = isCSR
    ? "Are you sure you want to close the RO Details? Any unsaved changes will be lost."
    : localeStrings[getPopupMessage()];
  return (
    <>
      {sliderview}
      <ConfirmPopup
        title={localeStrings["sq.newquote.location_bar.restart_modal_title"]}
        message={popupMessage}
        show={showAlertPopup}
        okText={
          localeStrings["sq.newquote.location_bar.restart_modal_ok_button"]
        }
        cancelText={
          localeStrings["sq.newquote.location_bar.restart_modal_cancel_button"]
        }
        okAction={() => {
          props.closeModal();
        }}
        cancelAction={() => {
          setShowAlertPopup(false);
        }}
        hideCancel={false}
        hideOk={false}
        buttonStyle="primary"
      />
    </>
  );
};

export default QuoteSlider;

QuoteSlider.defaultProps = {
  showSlide: false,
  flexWidth: true,
  sliderWidth: "85vw"
};

QuoteSlider.propTypes = {
  closeModal: PropTypes.func,
  flexWidth: PropTypes.bool,
  showSlide: PropTypes.bool,
  sliderWidth: PropTypes.string
};
