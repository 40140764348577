import isNaN from "lodash/isNaN";
import moment from "moment";

// @todo: check if this function is used anywhere in the app and delete if not.
const convertMinutesToHours = minutes => +(+minutes / 60).toFixed(2);

/**
 * Formats the original labor time value to the correct number format.
 * If the value provided is either not parseable or not a number, it returns the provided fallback value instead.
 * @param {string|number} val Original time value. It can be a string or a number.
 * @param {number} fallback Amount to return if the value is not parseable.
 * @returns {number}
 */
function formatLaborTime(val, fallback) {
  const valueType = typeof val;
  if (valueType === "string") {
    const parsedVal = parseFloat(val);
    return isNaN(parsedVal)
      ? fallback
      : Math.round((parsedVal + Number.EPSILON) * 100) / 100;
  } else if (valueType === "number" && !isNaN(val)) {
    return Math.round((val + Number.EPSILON) * 100) / 100;
  } else {
    return fallback;
  }
}

function getNoOfDays(startDate, endDate) {
  if (startDate === endDate) return 1;
  if (startDate && endDate) {
    // To set two dates to two variables
    const date1 = new Date(startDate);
    const date2 = new Date(endDate);

    // To calculate the time difference of two dates
    const differenceInTime = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    return differenceInDays;
  } else return 0;
}

function convertMinutesToHM(minutes) {
  console.log("convertMinutesToHM", minutes);
  const duration = moment.duration(minutes, "minutes");
  const hours = Math.floor(duration.asHours());
  const mins = String(duration.minutes()).padStart(2, "0");
  return `${hours}:${mins}`;
}

export {
  convertMinutesToHours,
  formatLaborTime,
  getNoOfDays,
  convertMinutesToHM
};
