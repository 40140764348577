import React, { useState, useEffect } from "react";
import Tooltip from "@cx/ui/Tooltip";
import Badge from "@cx/ui/Badge";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import { useEditServiceContext } from "../state/edit-service.context";
import { priceValueFormatter } from "../utils/format.util";
import { serviceTypes } from "../utils/edit-service.constants";
import isEmpty from "lodash/isEmpty";
import "./ServiceHeader.scss";
import { appTypes } from "../../constants/app.constants";
import { isDev } from "../../utils/is-dev";
import { ObjectPreview } from "./object-preview.component";

const ServiceHeader = () => {
  const { state } = useEditServiceContext();
  const {
    appType,
    originalService: { totalPrice: originalTotalPrice },
    username,
    serviceType,
    completedSteps,
    currentEditingService,
    debugMode,
    userPermissions
  } = state;
  const { service, quoteSummary } = state;
  const { totalPrice, name, totalPriceOverridden, totalPriceOverride } =
    service;
  const { serviceOptions, vehicleAttr } = completedSteps;
  const [serviceTotalPrice, setServiceTotalPrice] = useState(totalPrice || 0);

  useEffect(() => {
    console.log(
      "Service Header > totalPrice, originalTotalPrice",
      totalPrice,
      originalTotalPrice
    );
    // Case 1: Global ops - update service price when service option is selected
    if (serviceType === serviceTypes.GLOBALREPAIR) {
      setServiceTotalPrice(vehicleAttr || serviceOptions ? totalPrice : 0);
    } else {
      // Case 2: Non-global ops - render service price when totalPrice is updated
      setServiceTotalPrice(totalPrice);
    }
  }, [vehicleAttr, serviceOptions, totalPrice]);
  const showObjectPreview = appType !== appTypes.CSR && isDev();
  const getTitle = () => {
    let title = "";
    if (serviceType == serviceTypes.MENU) {
      title =
        appType === appTypes.CSR
          ? isEmpty(quoteSummary)
            ? "Add service: "
            : "Edit service: "
          : null;
    } else {
      title =
        appType === appTypes.CSR
          ? isEmpty(currentEditingService)
            ? "Add service: "
            : "Edit service: "
          : null;
    }
    return title;
  };
  return (
    <>
      <div className="edit-service-header">
        <span className="service-name">
          {getTitle()}
          {name}
        </span>
        {!debugMode
          ? ""
          : totalPriceOverridden && (
              <span className="edit-override-label-container">
                <Badge htmlId="overrideBadge" color="red">
                  {priceValueFormatter(totalPriceOverride)}
                </Badge>
              </span>
            )}
        <div className="price-with-tooltip-container">
          {/* serviceType check for rendering only on menu services will be removed as soon as other services require tooltip */}
          {serviceType === serviceTypes.MENU &&
          originalTotalPrice !== totalPrice ? (
            <Tooltip
              htmlId="totalPriceEdited"
              tooltipContent={`Price changed from ${priceValueFormatter(
                originalTotalPrice
              )} to ${priceValueFormatter(totalPrice)} by ${username}`}
              position="left"
            >
              <IconInfoOutline htmlId="iconFee" />
            </Tooltip>
          ) : (
            ""
          )}
          {userPermissions.canViewPrices ? (
            <span className="service-price">
              {priceValueFormatter(serviceTotalPrice)}
            </span>
          ) : null}
        </div>
      </div>
      {showObjectPreview ? <ObjectPreview /> : null}
    </>
  );
};

export default ServiceHeader;
