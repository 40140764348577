import cloneDeep from "lodash/cloneDeep";
import { appType, makeSecureRestApi } from "../../../api/xmmAxios";
import { stripPropertiesFromObjectArray } from "../../../utils/helper.util";
import { getQuoteStatusAndWorkflowAttentionTag } from "../../../utils/quote.util";
import has from "lodash/has";
import isNull from "lodash/isNull";
import isEmpty from "lodash/isEmpty";
import { generatePartId } from "../../../PartsLookupModule/utils/helper.util";
import { QuoteServiceTypes } from "../../page-wrapper/constants/page-wrapper.constants";
import { priceSourceLabels } from "../../../PartsLookupModule/constants/parts.constants";
const formatParts = (parts, quoteUserId) => {
  if (!parts) {
    return [];
  }
  const propertiesToRemove = [
    "partsPrice",
    "alternateParts",
    "dmsPending",
    "dmsPrice",
    "partNumberDefinedByDealerFlag",
    "qualifiers",
    "recordType",
    "relationship",
    "selected",
    "specification",
    "status",
    "unitOfMeasure",
    "vehicleAttributes"
  ];
  const partsToCompare = stripPropertiesFromObjectArray(
    parts,
    propertiesToRemove
  );
  const formattedParts = partsToCompare.map(servicePart => {
    const {
      partId: extPartId,
      partName: description,
      partPriceSource
    } = servicePart;
    const formattedPart = {
      ...servicePart,
      quoteServicePartId: servicePart?.quoteServicePartId || null,
      partPrice: servicePart.unitPrice * servicePart.quantity,
      extPartId: isNull(extPartId) ? generatePartId() : extPartId,
      description,
      dtDmsPartCode: servicePart?.dtDmsPartCode || null,
      priceSource:
        partPriceSource === priceSourceLabels.MANUAL
          ? partPriceSource
          : servicePart.priceSource,
      adjustedQuantity: servicePart.quantity,
      lastModByUserId: quoteUserId,
      manufacturer: servicePart.manufacturer || "OEM"
    };
    // Extra props removed from object
    delete formattedPart.partPriceSource;
    delete formattedPart.partId;
    delete formattedPart.partName;
    return formattedPart;
  });
  return formattedParts;
};

// BUGFIX- delete extra fields added under quoteservice for menu before updating quote payload for saving quote
const cleanExtraFieldsForQuoteService = quoteSummary => {
  const { quoteServices } = quoteSummary;
  if (!isEmpty(quoteServices)) {
    quoteServices.map(service => {
      if (service.quoteServiceType === QuoteServiceTypes.MENU) {
        delete service.parts;
        delete service.labors;
        delete service.labor;
      }
      return service;
    });
  }
};
const payloadGlobalOps = ({ quoteUserId, quoteSummary, selectedService }) => {
  const { extServiceId: selectedServiceId } = selectedService;
  const quoteSummaryCopy = cloneDeep(quoteSummary);
  cleanExtraFieldsForQuoteService(quoteSummaryCopy);
  const unmodifiedQuoteServices = quoteSummaryCopy.quoteServices.filter(
    service => {
      return service.extServiceId !== selectedServiceId;
    }
  );
  console.log(
    "payloadGlobalOps - unmodified quote services",
    unmodifiedQuoteServices
  );
  const quoteServiceToUpdate = {
    ...selectedService,
    labor: {
      ...selectedService.labor,
      lastModByUserId: quoteUserId
    },
    // @note: For Declined, Recall, Dealer Pub, Global repair: Expected String value from selectedService.dealershipNotes field
    dealershipNotes: selectedService?.dealershipNotes
      ? [{ note: selectedService?.dealershipNotes?.trim() }]
      : null,
    lastModByUserId: quoteUserId,
    parts: formatParts(selectedService.parts, quoteUserId)
  };

  unmodifiedQuoteServices.push(quoteServiceToUpdate);

  // * Removing warranty payload from payload for services
  unmodifiedQuoteServices?.map(service => {
    if (has(service, "warranty")) {
      delete service?.warranty;
    }
  });

  const quotePayload = {
    ...quoteSummaryCopy,
    quoteServices: [...unmodifiedQuoteServices],
    ...getQuoteStatusAndWorkflowAttentionTag(appType, quoteSummary),
    creationDateTime: null,
    lastModDateTime: null,
    appointmentCode: null,
    quoteExpirationDateTime: null,
    convertedToApptDate: null,
    totalPrice: null,
    subtotalPrice: null,
    expirationDays: 30,
    totalPartsPrice: null,
    totalLaborPrice: null
  };
  return quotePayload;
};

const getPayload = quoteServiceType => {
  switch (true) {
    case quoteServiceType === "DECLINED":
    case quoteServiceType === "RECALL":
      return payloadGlobalOps; // need testing for recall, declined
    default:
      return payloadGlobalOps;
  }
};

const updateQuote = payload => {
  const { confirmationId, dealerCode } = payload;
  // * Removing warranty payload from payload for services
  payload?.quoteServices?.map(service => {
    if (has(service, "warranty")) {
      delete service?.warranty;
    }
  });
  const restUrl = `quote/${dealerCode}/${confirmationId}`;
  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "put",
        data: payload
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to save changes."
    );
  });
};

/**
 * Patches a single service line.
 * @param dealerCode Dealer code.
 * @param confirmationId Quote confirmation number (or RO number).
 * @param serviceId The id of the service being patched.
 * @param currentUserId The id of the currently logged-in user, who is making the change.
 * @param payload Object with just the service properties that need to be patched.
 * @returns {Promise<object>} Full updated quote.
 * @see https://csrapi.dev6.xtimeappsnp.xtime.com/rest/swagger-ui/#/service-resource/updateServiceUsingPATCH
 */
const patchService = (
  dealerCode,
  confirmationId,
  serviceId,
  currentUserId,
  payload
) => {
  const restUrl = `service/${serviceId}/quote/${dealerCode}/${confirmationId}`;
  payload.lastModByUserId = payload.lastModByUserId ?? currentUserId;

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "patch",
        data: payload
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to save changes."
    );
  });
};

/**
 * Closes one or more payers on an RO.
 * @param dealerCode Dealer code.
 * @param quoteId Quote id (the numeric PK field, not the confirmationId or roNumber).
 * @param payload Array indicating which payers to close, with the following fields for each:
 *        payerId (integer, required); lastModBy (integer, required);
 *        paymentMethod (string, optional, only applies to Customer payers).
 * @returns {Promise<object>} Object with a `success` boolean field and some other info (not the full quote object).
 * @see https://csrapi.dev6.xtimeappsnp.xtime.com/rest/swagger-ui/#/payer-resource/updatePayerUsingPATCH
 */
const closePayers = (dealerCode, quoteId, payload) => {
  const restUrl = `csr/dealerCode/${dealerCode}/csrId/${quoteId}/closePayer`;

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "post",
        data: payload
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to save changes."
    );
  });
};

/**
 * Patches a single payer object.
 * @param dealerCode Dealer code.
 * @param confirmationId Quote confirmation number (or RO number).
 * @param payerId The id of the payer being patched.
 * @param currentUserId The id of the currently logged-in user, who is making the change.
 * @param payload Object with just the service properties that need to be patched.
 * @returns {Promise<object>} Full updated quote.
 * @see https://csrapi.dev6.xtimeappsnp.xtime.com/rest/swagger-ui/#/payer-resource/updatePayerUsingPATCH
 */
const patchPayer = (
  dealerCode,
  confirmationId,
  payerId,
  currentUserId,
  payload
) => {
  const restUrl = `payer/${payerId}/dealercode/${dealerCode}/quote/${confirmationId}`;
  payload.payerId = payerId;
  payload.lastModByUserId = payload.lastModByUserId ?? currentUserId;

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "patch",
        data: payload
      },
      response => {
        resolve(response);
      },
      error => {
        reject(error);
      },
      "Unable to save changes."
    );
  });
};

/**
 * Patches the approval code for a service contract.
 * @param {string} dealerCode - The dealer's code.
 * @param {string} confirmationId - The ID of the confirmation.
 * @param {string} payerId - The ID of the payer.
 * @param {string} approvalCode - The new approval code to set.
 * @returns {Promise<Object>} A promise that resolves with the API response.
 * @see https://csrapi.dev6.xtimeappsnp.xtime.com/rest/swagger-ui/#/payer-resource/patchPayerUsingPATCH
 *
 */
const patchServiceContractApprovalCode = (
  dealerCode,
  confirmationId,
  payerId,
  approvalCode
) => {
  const restUrl = `payer/${payerId}/patchpayer/dealercode/${dealerCode}/quote/${confirmationId}`;
  const payload = [
    {
      op: "replace",
      path: "/serviceContract/approvalCode",
      value: approvalCode
    }
  ];

  return new Promise((resolve, reject) => {
    makeSecureRestApi(
      {
        url: restUrl,
        method: "PATCH",
        data: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json-patch+json" // Set header to application/json-patch+json
        }
      },
      resolve,
      reject,
      "Unable to save changes."
    );
  });
};

export default {
  getPayload,
  updateQuote,
  patchService,
  closePayers,
  patchPayer,
  patchServiceContractApprovalCode
};
