import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./service-details-section.component.scss";
import { useNewQuoteContext } from "../../../../state/NewQuoteContext";
import sortBy from "lodash/sortBy";
import { formatDateString } from "../../../utils/format";
import format from "../../../../features/repair-order/utils/format";
import { extractNotes } from "../../../utils/data-transformer";
import { getTechStatusBadge } from "../../../../utils/quote.util";
import Badge from "@cx/ui/Badge";

const ServiceDetailsSection = ({ userPermissions, service }) => {
  const { state } = useNewQuoteContext();
  const { dealerProperties, quoteSummary } = state;
  const isTechTimeCaptureEnabled =
    dealerProperties?.ENABLE_DMSPLUS_TECH_TIME_CAPTURE === "Y";
  const [formattedTechnicianList, setFormattedTechnicianList] = useState([]);
  const warrantyDetails = service?.warranty?.warrantyDetails;

  const getFormattedTechList = useCallback(
    techRows => {
      const augmentedTechRows = techRows.map(techRow => {
        const techUser =
          state?.technicians?.find(
            technician => parseInt(technician?.id, 10) === techRow?.techId
          ) ?? techRow?.techUser;
        const techName = techUser
          ? `${techUser.firstName} ${techUser.lastName}`
          : null;
        const dateString = formatDateString(
          new Date(techRow.workStarted ?? techRow.actualTimeStart ?? Date.now())
        );
        const workingDurationInHours = techRow.workingDuration
          ? techRow.workingDuration / 60
          : techRow.actualTimeDuration;
        const actualTimeString = format.decimal(workingDurationInHours ?? 0, 2);

        return {
          ...techRow,
          techUser,
          techName,
          dateString,
          actualTimeString
        };
      });

      const filteredTechRows = augmentedTechRows.filter(
        techRow => !!techRow.techUser
      );

      const sortedTechRows = sortBy(filteredTechRows, [
        "techName",
        "techTimeId"
      ]);

      return sortedTechRows;
    },
    [state?.technicians, state?.dealerProperties]
  );

  useEffect(() => {
    if (state?.technicians?.length > 0 && service?.technicians?.length > 0) {
      const list = getFormattedTechList(service.technicians);
      setFormattedTechnicianList(list);
    } else {
      setFormattedTechnicianList([]);
    }
  }, [state?.technicians, service?.technicians, getFormattedTechList]);

  return (
    <div className="service-details-section-container">
      <h6 className="heading">Service details</h6>
      <div className="service-details-section-box">
        <table className="technicians">
          <thead>
            <tr>
              <th>Technician</th>
              {isTechTimeCaptureEnabled ? <th>Status</th> : null}
              {service.payTypeCode === "W" ? <th>Correction code</th> : null}
              <th>Flag time</th>
              <th>Date</th>
              {isTechTimeCaptureEnabled ? <th>Work type</th> : null}
              <th>Actual time</th>
              {userPermissions.canViewPrices ? <th>Labor rate</th> : null}
            </tr>
          </thead>
          <tbody>
            {formattedTechnicianList?.map(techRow => {
              return (
                <tr key={`techRow${techRow.techTimeId}`}>
                  <td title={JSON.stringify(techRow)}>
                    {techRow.techName}
                    {techRow.isPrimary &&
                    (service.payTypeCode === "W" ||
                      isTechTimeCaptureEnabled) ? (
                      <Badge
                        htmlId="technicianName"
                        color="gray"
                        className="tech-badge"
                      >
                        P
                      </Badge>
                    ) : null}
                  </td>
                  {isTechTimeCaptureEnabled ? (
                    <td>
                      {getTechStatusBadge(techRow, service, quoteSummary)}
                    </td>
                  ) : null}
                  {service.payTypeCode === "W" ? (
                    <td>{techRow.correctionCode ?? "---"}</td>
                  ) : null}
                  <td>{`${techRow?.flagTimeEnd ?? "---"} hrs`}</td>
                  <td>{techRow?.dateString}</td>
                  {isTechTimeCaptureEnabled ? (
                    <td>{techRow?.workType}</td>
                  ) : null}
                  <td>{`${techRow?.actualTimeString} hrs`}</td>
                  {userPermissions.canViewPrices ? (
                    <td>{techRow?.laborRateCode ?? "---"}</td>
                  ) : null}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {service?.payTypeCode === "W" ? (
        <div className="service-details-section-box">
          <div>
            <div className="section-detail-title">Claim type</div>
            <div className="section-detail-data">
              {warrantyDetails?.claimType ?? "---"}
            </div>
          </div>
          <div>
            <div className="section-detail-title">Diagnostic code</div>
            <div className="section-detail-data">
              {warrantyDetails?.diagnosticCode ?? "---"}
            </div>
          </div>
          <div>
            <div className="section-detail-title">Symptom code</div>
            <div className="section-detail-data">
              {warrantyDetails?.symptomCode ?? "---"}
            </div>
          </div>
          <div>
            <div className="section-detail-title">Coverage code</div>
            <div className="section-detail-data">
              {warrantyDetails?.coverageCode ?? "---"}
            </div>
          </div>
          <div>
            <div className="section-detail-title">Primary failed part</div>
            <div className="section-detail-data">
              {warrantyDetails?.casualPart ?? "---"}
            </div>
          </div>
        </div>
      ) : null}
      <div className="service-details-section-box">
        <div>
          <span className="section-detail-title">
            Internal dealership notes
          </span>
          <span className="section-detail-data">
            {extractNotes(service?.dealershipNotes) ?? "---"}
          </span>
        </div>
      </div>
      <div className="service-details-section-box">
        <div>
          <span className="section-detail-title">Concern</span>
          <span className="section-detail-data">
            {service?.complaint || "---"}
          </span>
        </div>
      </div>
      <div className="service-details-section-box">
        <div>
          <span className="section-detail-title">Cause</span>
          <span className="section-detail-data">{service?.cause || "---"}</span>
        </div>
      </div>
      <div className="service-details-section-box">
        <div>
          <span className="section-detail-title">Correction</span>
          <span className="section-detail-data">
            {service?.correction || "---"}
          </span>
        </div>
      </div>
    </div>
  );
};
ServiceDetailsSection.propTypes = {
  userPermissions: PropTypes.object.isRequired,
  service: PropTypes.object.isRequired
};

ServiceDetailsSection.defaultProps = {
  userPermissions: null,
  service: null
};

export default ServiceDetailsSection;
