import React, { useState } from "react";
import PropTypes from "prop-types";
import TextArea from "@cx/ui/TextArea";
import "./csr.scss";

const InternalNotes = props => {
  const { dealershipNotes, dispatchDealershipNotes } = props;

  const [internalNotes, setInternalNotes] = useState(dealershipNotes);
  const [internalNotesCharCount, setInternalNotesCauseCharCount] = useState(
    dealershipNotes?.length || 0
  );

  const onChangeInput = event => {
    setInternalNotes(event.target.value);
    setInternalNotesCauseCharCount(event.target.value.length);
  };

  const onBlurNotes = event => {
    dispatchDealershipNotes(event.target.value || "");

    // Test hook to trigger error handling from inside a component.
    if (
      event.target.value.trim() === "TEST_HOOK_THROW_ERROR_FROM_INTERNAL_NOTES"
    ) {
      throw new Error("TEST_HOOK_THROW_ERROR_FROM_INTERNAL_NOTES");
    }
  };

  return (
    <>
      <div className="service-csr-nput-label-content">
        <div>
          <span className="label-color">
            Internal dealer notes - NOT SHOWN TO CUSTOMER
          </span>
        </div>
        <span className="character-string label-color">
          Character count: {internalNotesCharCount} /1000
        </span>
      </div>
      <TextArea
        htmlId="InternalNoteInput"
        className="input-box"
        label="Internal notes"
        displayLabel={false}
        name="internalNotes"
        value={internalNotes ?? ""}
        rows={4}
        onChange={onChangeInput}
        onBlur={onBlurNotes}
        maxLength={1000}
      />
    </>
  );
};

export default InternalNotes;

InternalNotes.defaultProps = {
  dealershipNotes: null,
  dispatchDealershipNotes: () => {}
};

InternalNotes.propTypes = {
  dealershipNotes: PropTypes.string,
  dispatchDealershipNotes: PropTypes.func
};
