import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ButtonToolbar from "react-bootstrap/lib/ButtonToolbar";
import ModalDialog from "@cx/ui/ModalDialog";
import CheckBoxList from "@cx/ui/CheckBoxList";
import themePrefixer from "@cx/ui/themePrefixer";
import Button from "@cx/ui/Button";
import SelectInput from "@cx/ui/SelectInput";
import TruncateText from "@cx/ui/TruncateText";
import { isItCorePart } from "../utils/helper.util";
import { getInventoryPartsLocationItems } from "../utils/parts.util";

const RemovePartModal = ({
  show,
  removePartData,
  inventoryPartsLocation,
  isCorePart,
  onCancel,
  onSave
}) => {
  const [showRemovePartModal, setShowRemovePartModal] = useState(show);
  const [inventorySelection, setInventorySelection] = useState({
    returnToInventory: false,
    notReturnToInventory: false
  });
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const { part } = removePartData;
  const inventoryPartsLocationOptions = getInventoryPartsLocationItems(
    inventoryPartsLocation?.items
  );

  useEffect(() => {
    setShowRemovePartModal(show);
  }, [show]);

  const handleSave = () => {
    onSave({
      ...removePartData,
      locationId: selectedLocationId,
      returnToInventory: inventorySelection.returnToInventory,
      actionType: "Remove"
    });
    setShowRemovePartModal(false);
    setSelectedLocationId("");
  };

  const handleCheckboxChange = cxEvent => {
    const { value } = cxEvent.target;
    setInventorySelection({
      returnToInventory: value.includes("return"),
      notReturnToInventory: value.includes("remove")
    });
  };

  const onSelectLocation = ev => {
    const location = ev?.target?.value;
    const partsLocations = inventoryPartsLocation?.items;
    const selectedLocation = partsLocations.find(partLocation => {
      return partLocation.name === location;
    });
    setSelectedLocationId(selectedLocation.id);
  };

  return (
    <ModalDialog
      htmlId="removePartModal"
      className="core-return-modal"
      show={showRemovePartModal}
      backdrop="static"
      header={<h4 className={`${themePrefixer()}modal-title`}>Inventory</h4>}
      footer={
        <div className="lookup-modal-footer">
          <ButtonToolbar>
            <Button
              htmlId="removePartCancelBtn"
              type="button"
              buttonStyle="secondary"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              htmlId="removePartSaveBtn"
              type="button"
              buttonStyle="primary"
              onClick={handleSave}
            >
              Save
            </Button>
          </ButtonToolbar>
        </div>
      }
      onHide={onCancel}
    >
      <div>
        {part?.oemPartNumber ? (
          <div>
            <b>Part number:</b> {part.oemPartNumber}
          </div>
        ) : null}
        {part?.description ? (
          <TruncateText htmlId="TruncateTextSingleLine">
            <b>Description:</b> {part.description}
          </TruncateText>
        ) : null}
        {part?.adjustedQuantity ? (
          <div>
            <b>Qty:</b> {part.adjustedQuantity}
          </div>
        ) : null}
        <hr className="section-divider" />

        {!isItCorePart(isCorePart) ? (
          <>
            <CheckBoxList
              htmlId="checkBoxReturnToInventory"
              name="checkBoxReturnToInventory"
              values={inventorySelection.returnToInventory ? ["return"] : []}
              onChange={handleCheckboxChange}
              options={[{ value: "return", label: "Yes, add to inventory" }]}
            />
            {inventorySelection.returnToInventory ? (
              <SelectInput
                htmlId="selectInputPartsLocation"
                label="Location"
                name="selectInputPartsLocation"
                options={inventoryPartsLocationOptions}
                onChange={onSelectLocation}
                required
              />
            ) : null}
            <CheckBoxList
              htmlId="checkBoxRemovePart"
              name="checkBoxRemovePart"
              values={inventorySelection.notReturnToInventory ? ["remove"] : []}
              onChange={handleCheckboxChange}
              options={[
                { value: "remove", label: "No, do not add to inventory" }
              ]}
            />
          </>
        ) : null}
      </div>
    </ModalDialog>
  );
};

RemovePartModal.propTypes = {
  show: PropTypes.bool.isRequired,
  removePartData: PropTypes.object.isRequired,
  inventoryPartsLocation: PropTypes.object.isRequired,
  isCorePart: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

export default RemovePartModal;
