const getLaborPriceService = async (params, axiosInstance) => {
  const {
    dealerCode,
    laborHours,
    vehicle,
    payTypeCode,
    serviceTypeCode,
    skillLevel,
    isMenuPackage
  } = params;
  const { make } = vehicle;
  const restUrl = `quote/services/labor/${dealerCode}/price`;
  // @note: Usage of spread operator below.
  // If params.dealerLaborRateId doesn't arrive, it won't be included in the request params; an empty object gets spread.
  // If it arrives, it does get sent; { dealerLaborRateId: params.dealerLaborRateId } gets spread into the request params object.
  const reqParams = {
    make,
    laborHours,
    payTypeCode,
    serviceTypeCode,
    skillLevel,
    isMenuPackage,
    ...(params.dealerLaborRateId
      ? { dealerLaborRateId: params.dealerLaborRateId }
      : {})
  };
  try {
    const response = await axiosInstance.get(restUrl, {
      params: reqParams
    });

    return response.data;
  } catch (error) {
    console.error(error);
    return {};
  }
};

/**
 * Fetches labor pricing information for a specific vehicle make from a dealer's service quote API.
 *
 * @param {Object} params - The parameters for the API call.
 * @param {string} params.dealerCode - The code of the dealer to query.
 * @param {Object} params.vehicle - The vehicle information.
 * @param {string} params.vehicle.make - The make of the vehicle.
 * @param {Object} axiosInstance - The Axios instance for making HTTP requests.
 *
 * @returns {Promise<Object>} The labor pricing information for the specified vehicle make.
 */
const getLaborPriceByMake = async (params, axiosInstance) => {
  const { dealerCode, vehicle } = params;
  const { make } = vehicle;
  const restUrl = `quote/services/${dealerCode}/laborPricing`;
  const reqParams = { make };

  try {
    const response = await axiosInstance.get(restUrl, {
      params: reqParams
    });

    return response.data;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export { getLaborPriceService, getLaborPriceByMake };
