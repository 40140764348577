import React from "react";
import Badge from "@cx/ui/Badge";
import { appTypes } from "../constants/app.constants";
import QuoteStatusConstant, {
  payTypeCodes
} from "../constants/quote-status.constants";

export const getQuoteStatusAndWorkflowAttentionTag = (
  appType,
  quoteSummary
) => {
  return {
    quoteStatus:
      appType === appTypes.SQ &&
      quoteSummary?.quoteStatus === QuoteStatusConstant.REQUEST_ASSISTANCE
        ? quoteSummary.quoteStatus
        : QuoteStatusConstant.PROGRESS,
    ...(appType === appTypes.SQ &&
      quoteSummary?.workflowAttentionTag && {
        workflowAttentionTag: quoteSummary.workflowAttentionTag
      })
  };
};

/**
 * Extracts and returns a list of unique full names of technicians from a potentially nested array of technicians.
 * Each technician object should have a 'techUser' property with optional 'firstName' and 'lastName'.
 * If these are missing, empty strings are used in their place.
 *
 * @param {Array<Array<{techUser: {firstName?: string, lastName?: string}}>>} techniciansList - A nested array of technician objects.
 * @returns {string[]} An array of unique technician full names, with any missing names represented as empty strings.
 */
export const getUniqueTechs = techniciansList => {
  // Ensure that techniciansList is an array and flatten it
  const flatList = Array.isArray(techniciansList) ? techniciansList.flat() : [];

  // Use a Set to store unique names
  const uniqueNames = new Set(
    flatList
      .filter(t => t?.techUser) // Filter out entries without a techUser
      .map(
        // Create a full name string, using empty strings for missing firstName or lastName
        t => `${t.techUser.firstName ?? ""} ${t.techUser.lastName ?? ""}`.trim()
      )
  );

  return Array.from(uniqueNames); // Convert the set back to an array
};

/**
 * Builds the message used to indicate what service a technician is currently working on.
 * @param {object} currentWorkingLine The technician's data in the `currentlyWorkingOnROs` array from the
 * {@link https://servicequotingapi.dev6.xtimeappsnp.xtime.com/rest/swagger-ui/?urls.primaryName=CSR%20Api#/technician-resource/getTechStatusUpdateUsingGET|getTechStatusUpdate}
 * API response
 * @returns {string}
 */
export function getClockRunningMessage(currentWorkingLine) {
  return `Clock running on RO ${currentWorkingLine?.roNumber} - line ${currentWorkingLine?.serviceLineNumber}`;
}

/**
 * Builds a technician status badge for a given tech row.
 * @param {object} techRow Tech row (an item from the service.technicians array)
 * @param {object} service Service that this tech row belongs to.
 * @param {object} ro RO that this tech row is under.
 * @param {array?} currentlyWorkingOnROs Data from the
 * {@link https://servicequotingapi.dev6.xtimeappsnp.xtime.com/rest/swagger-ui/?urls.primaryName=CSR%20Api#/technician-resource/getTechStatusUpdateUsingGET|getTechStatusUpdate}
 * API response.
 * @returns {Element} CX Badge element.
 */
export function getTechStatusBadge(
  techRow,
  service,
  ro,
  currentlyWorkingOnROs
) {
  let color, label, tooltip;

  // Find the service that this technician is currently working on, if any.
  const currentWorkingLine = currentlyWorkingOnROs?.find(
    x => x.techId === techRow.techId
  );
  const isWorkingOnAnotherLine =
    currentWorkingLine &&
    (currentWorkingLine?.roNumber !== ro.roNumber ||
      currentWorkingLine?.serviceLineNumber !== service.serviceLineNumber);

  switch (techRow.status) {
    case "WORKING":
      color = "gray";
      label = "Time started";
      break;
    case "BREAK":
      color = ro.isBayDisabled ? "count" : "orange";
      label = "On break";
      break;
    case "HOLD_PARTS":
      color = ro.isBayDisabled ? "count" : "orange";
      label = "Parts hold";
      break;
    case "HOLD_APPROVAL":
      color = ro.isBayDisabled ? "count" : "orange";
      label = "Approval hold";
      break;
    case "STOP":
      color = "purple";
      label = techRow.isWorkingDurationOverridden ? "Overridden" : "Time ended";
      tooltip = techRow.overrideReason;
      break;
    default:
      color = isWorkingOnAnotherLine ? "orange" : "gray";
      label = isWorkingOnAnotherLine
        ? "Running time on another line"
        : "Not started";
      tooltip = isWorkingOnAnotherLine
        ? getClockRunningMessage(currentWorkingLine)
        : undefined;
      break;
  }

  return (
    <Badge
      htmlId={`techStatusBadge${techRow.techTimeId}`}
      color={color}
      title={tooltip}
    >
      {label}
    </Badge>
  );
}

export const doesServiceContractExists = payTypes =>
  payTypes?.find(payType => payType?.payCode === payTypeCodes.SERVICE_CONTRACT);
