/* eslint-disable react-hooks/exhaustive-deps */

import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@cx/ui/Button";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isNull from "lodash/isNull";
import ModalDialog from "@cx/ui/ModalDialog";
import themePrefixer from "@cx/ui/themePrefixer";
import ResultsPaneWrapper from "./parts-sections.container";
import SelectedPartsGrid from "./selected-parts-grid.component";
import {
  OperationSources,
  actionTypes,
  applications
} from "../constants/parts.constants";
import makeCodes from "../constants/manufacturer-codes.constants";
import useComponentDidMount from "../hooks/useComponentDidMount";
import cloneDeep from "lodash/cloneDeep";
import RemovePartModal from "./remove-part-modal.component";
import CorePartReturnModal from "./core-return-modal.component";
import SubmitButton from "@cx/ui/SubmitButton";

import {
  findPart,
  calculateTotalPrice,
  stripPropertiesFromObjectArray,
  generatePartId,
  isPartInstock
} from "../utils/helper.util";
import {
  usePartsLookupContext,
  PartsLookupProvider,
  Actions
} from "../state/parts-lookup.context";
import {
  containsPartsWithCostAttributes,
  resetDmsPending,
  updateLookupPartsForMenus
} from "../utils/parts.util";
import round from "lodash/round";
import { replaceLastCWithX } from "../utils/value";

const PartsLookupComponent = props => {
  const partsGridLookupRef = useRef(null);
  const {
    appType,
    isPartsView,
    userPermissions,
    quoteSummary,
    service,
    rawOperationDetails,
    selectedVehicleAttributes,
    completedSteps,
    lookupParts,
    partsPricingAndInventory,
    inventoryPartsLocation,
    serviceType,
    savedParts,
    actionType,
    onServiceChange,
    onCancelHandler,
    onSaveHandler,
    getPartsCallback,
    commonConsumerId,
    onPerformPartAction, // @ERP action menu handler approve/update parts
    isCsrFinalized, // @ERP
    dealershipNotes,
    emergencyPartProperty,
    getPurchaseOrderDetails
  } = props;
  const { state, dispatch, ctxGtmEvent } = usePartsLookupContext();
  const {
    lookupPartsList,
    serviceParts,
    initialServiceParts,
    menuServiceOriginalParts
  } = state;
  // @note: service.parts will have parts set from Details component for all service types
  // @todo-poc: This local list used to show dealer-pub case parts directly under service
  const [addedParts, setAddedParts] = useState([]);
  const [selectedVehicleAttributeMap, setSelectedVehicleAttributeMap] =
    useState({});
  const [showNotes, setShowNotes] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [showLookupBtn, setShowLookupBtn] = useState(true);
  const [totalPartsPrice, setTotalPartsPrice] = useState(0);
  const [hasInitialParts, setHasInitialParts] = useState(false);

  // @ERP
  const [removePartData, setRemovePartData] = useState(null);
  const [showRemovePart, setShowRemovePart] = useState(false);
  const [showCorePartReturnModal, setShowCorePartReturnModal] = useState(false);
  const [coreReturnPart, setCoreReturnPart] = useState(null);

  const parseDealerMakesProperty = val => {
    if (val && val.length) {
      try {
        const makes = val.split("|").map(item => {
          const values = item.split(",");
          return {
            make: values[0],
            code: values[1],
            description: values[2]
          };
        });
        return makes;
      } catch (err) {
        console.error(`parseDealerMakesProperty error: ${err}`);
        return null;
      }
    } else {
      return null;
    }
  };

  // @note- refactor below: didmount called once when edit service page is loaded
  useComponentDidMount(() => {
    if (!isEmpty(appType)) {
      dispatch({
        type: Actions.SET_APP_TYPE,
        payload: appType
      });
    }
    // @csr-logic
    if (state.isPartsView !== isPartsView) {
      dispatch({
        type: Actions.SET_IS_PARTS_VIEW,
        payload: isPartsView
      });
    }
    if (!isEmpty(rawOperationDetails)) {
      dispatch({
        type: Actions.SET_RAWOPERATION_DETAILS,
        payload: rawOperationDetails
      });
    }
    dispatch({
      type: Actions.SET_DEBUG_MODE,
      payload: props.debugMode
    });
    // read props {userPermissions} passed in Details via EditServiceModule
    dispatch({
      type: Actions.SET_USER_PERMISSIONS,
      payload: props.userPermissions
    });
    // Currently, only menu services are being passed originalParts so removed parts can be retrived when re-editing
    if (
      service.operationSource === OperationSources.MENU &&
      !isEmpty(service.originalParts)
    ) {
      dispatch({
        type: Actions.SET_MENU_SERVICE_ORIGINAL_PARTS,
        payload: service.originalParts
      });
    }
    if (actionType === actionTypes.MODIFY_PARTS) {
      setShowModal(true);
    }
  });
  // This useEffect listens for changes in lookupParts that happen when we get partsPricingAndInventory from open track api.
  useEffect(() => {
    // Previously, a clone of lookupParts was made and used here.
    // As per defect DE307915, it was deeemed unnecessary and risky if using JSON.stringify->parse was used to make the clone because of edge case cyclic onject values.
    resetDmsPending(lookupParts, false);
    if (actionType !== actionTypes.EDIT_NEW) return;

    // initialize catalog api returned parts at service level
    let currentLookupParts = lookupParts;
    const isMenuSource = service.operationSource === OperationSources.MENU;
    if (isMenuSource) {
      // RO case: check if service level parts has cost attrs {costPrice, unitCost}; then read service.parts;
      if (appType === applications.CSR) {
        // Determine the preferred list of parts based on the presence of cost attributes
        if (containsPartsWithCostAttributes(service?.parts)) {
          currentLookupParts = service.parts; // Use service parts if any part has cost attributes
        } else if (containsPartsWithCostAttributes(lookupParts)) {
          // Fallback to lookupParts if it has any parts with cost attributes
          currentLookupParts = lookupParts;
        } else {
          // Default to service parts if none match the criteria
          currentLookupParts = service.parts || [];
        }
      } else {
        // SQ case: if parts deleted in edit menu service, but menu not saved; parts should not retain in edit page
        currentLookupParts = service?.parts || [];
      }
      console.log("PartsLookup -> lookupParts", lookupParts);
      console.log("PartsLookup -> service.parts", service.parts);
      console.log("PartsLookup -> currentLookupParts", currentLookupParts);
      if (!isEmpty(service.originalParts)) {
        resetDmsPending(menuServiceOriginalParts, false);
        // This method will process original menu service parts and update selected property
        updateLookupPartsForMenus(currentLookupParts, service);
      }
    }

    const preselectedParts = currentLookupParts.filter(part => part.selected);
    const nonSelectedParts = currentLookupParts.filter(part => !part.selected);

    setAddedParts(preselectedParts);
    dispatch({ type: Actions.SET_SERVICE_PARTS, payload: preselectedParts });
    dispatch({
      type: Actions.SET_LOOKUP_PARTS_LIST,
      payload: nonSelectedParts
    });
  }, [lookupParts, menuServiceOriginalParts, service.operationSource]);

  useEffect(() => {
    // The following logic only applies in edit service and modify parts within summary
    if (actionType !== actionTypes.EDIT_NEW) {
      // Same case as indicated in previous useEffect: clone of lookupParts not used anymore.
      // NOTE: not making !isEmpty check could result in infnite loop for global operations!!!
      if (!isEmpty(savedParts)) {
        // @todo: check here which parts to send
        setAddedParts(savedParts);
        dispatch({
          type: Actions.SET_SERVICE_PARTS,
          payload: savedParts
        });
        if (!hasInitialParts && actionType === actionTypes.MODIFY_PARTS) {
          dispatch({
            type: Actions.SET_INITIAL_SERVICE_PARTS,
            payload: savedParts
          });
          setHasInitialParts(true);
        }
        if (!isEmpty(lookupParts)) {
          resetDmsPending(lookupParts, false);
          const savedPartsIds = savedParts.map(part => part.partId);
          const missingParts = lookupParts.filter(part => {
            return !savedPartsIds.includes(part.partId);
          });
          dispatch({
            type: Actions.SET_LOOKUP_PARTS_LIST,
            payload: missingParts
          });
        }
      } else {
        // NOTE: removing this check will cause issue in quote and advisor views!!!
        //       need to revisit
        if (state.isPartsView) {
          setAddedParts(savedParts);
          dispatch({
            type: Actions.SET_SERVICE_PARTS,
            payload: savedParts
          });
        }
        if (!isEmpty(lookupParts)) {
          resetDmsPending(lookupParts, false);
          dispatch({
            type: Actions.SET_LOOKUP_PARTS_LIST,
            payload: lookupParts
          });
        }
      }
    }
  }, [savedParts, lookupParts, hasInitialParts]);
  useEffect(() => {
    if (
      service.operationSource === OperationSources.MENU &&
      !isEmpty(partsPricingAndInventory)
    ) {
      dispatch({
        type: Actions.UPDATE_MENU_SERVICE_ORIGINAL_PARTS,
        payload: { partsPricingAndInventory }
      });
    }
  }, [partsPricingAndInventory]);
  useEffect(() => {
    // @note: We used to rely on rawOperationDetails.catalogSource here
    if (!isEmpty(service.operationSource)) {
      setShowNotes(service.operationSource === OperationSources.GLOBALCATALOG);
    }
    // !remove: selectedVehicleAttributes dependency check
    if (
      !isEmpty(selectedVehicleAttributes) &&
      actionType !== actionTypes.MODIFY_PARTS
    ) {
      const vehAttrKeys = Object.keys(selectedVehicleAttributes);
      for (let i = 0; i < vehAttrKeys.length; i++) {
        const key = vehAttrKeys[i];
        if (selectedVehicleAttributes[key] === "I don't know") {
          selectedVehicleAttributes[key] = "idk";
        }
      }
      setSelectedVehicleAttributeMap(selectedVehicleAttributes);
      dispatch({
        type: Actions.SET_SELECTED_VEHICLE_ATTRS,
        payload: selectedVehicleAttributes
      });
    }
  }, [selectedVehicleAttributes, lookupPartsList]);

  useEffect(() => {
    if (!isEmpty(serviceParts)) {
      const totalPrice = calculateTotalPrice(serviceParts).toFixed(2);
      setTotalPartsPrice(Number(totalPrice));
      if (actionType !== actionTypes.MODIFY_PARTS) {
        const partsResult = {
          parts: serviceParts,
          deletedItem: null,
          totalPartsPrice: Number(totalPrice)
        };
        getPartsCallback(partsResult);
      } else {
        const propertiesToRemove = [
          "dmsPrice",
          "priceSource",
          "recordType",
          "unitPrice",
          "quantityOnHand",
          "selected",
          "partPriceSource" //* adding this property as it creating issue while setting flag for changed while opening modify parts section
        ];
        const strippedServiceParts = stripPropertiesFromObjectArray(
          serviceParts,
          propertiesToRemove
        );
        const strippedInitialParts = stripPropertiesFromObjectArray(
          initialServiceParts,
          propertiesToRemove
        );
        const partsNotEqual = !isEqual(
          strippedServiceParts,
          strippedInitialParts
        );
        if (partsNotEqual) {
          onServiceChange(true);
        }
      }
    } else {
      // @note: in modify parts, totalPartsPrice needs to be set to 0 when there are no srevice parts
      if (actionType === actionTypes.MODIFY_PARTS) {
        setTotalPartsPrice(0);
      }
    }
  }, [serviceParts, initialServiceParts]);
  // !remove - completedSteps dependency
  useEffect(() => {
    // The following logic won't apply when modifying parts in summary
    if (
      actionType !== actionTypes.MODIFY_PARTS &&
      !isEmpty(completedSteps) &&
      completedSteps.serviceOptions &&
      service.operationSource === OperationSources.GLOBALCATALOG
    ) {
      const totalPrice = calculateTotalPrice(serviceParts).toFixed(2);
      const partsResult = {
        parts: serviceParts,
        deletedItem: null,
        totalPartsPrice: Number(totalPrice)
      };
      getPartsCallback(partsResult);
    }
  }, [completedSteps, serviceParts]);
  // TODO: review why we need serviceType in parts module?
  useEffect(() => {
    dispatch({
      type: Actions.SET_SERVICE_TYPE,
      payload: serviceType
    });
    dispatch({
      type: Actions.SET_MAKE,
      payload: props.make
    });
  }, [serviceType, props.make]);
  useEffect(() => {
    dispatch({
      type: Actions.SET_DEALER_PROPERTIES,
      payload: props.dealerProperties
    });
  }, [props.dealerProperties]);
  useEffect(() => {
    dispatch({
      type: Actions.SET_DEALER_MAKES,
      payload: parseDealerMakesProperty(props.dealerMakesProperty) ?? makeCodes
    });
  }, [props.dealerMakesProperty]);
  // @todo-beta: read payTypeCode from props
  useEffect(() => {
    dispatch({
      type: Actions.SET_PAYTYPE_CODE,
      payload: props.payTypeCode
    });
  }, [props.payTypeCode]);

  const closePartsLookup = () => {
    setShowModal(false);
  };
  // @todo: done click will read context.serviceParts to service
  const doneHandler = async () => {
    closePartsLookup();
    // DE505520: duplicated partIds break the grid. If we find any, we generate a new one
    appType === applications.CSR &&
      serviceParts.forEach(servicePart => {
        const duplicatedPartIds = serviceParts.filter(
          part => part.extPartId === servicePart.extPartId
        );
        if (duplicatedPartIds.length > 1) {
          const newPartId = generatePartId();
          servicePart.extPartId = newPartId;
          servicePart.partId = newPartId.toString();
        }
      });
    // If action type is EDIT_SERVICE, update state addedParts to show selected parts under service grid
    if (actionType !== actionTypes.MODIFY_PARTS) {
      setAddedParts(serviceParts);
      // Else, action type is MODIFY_PARTS, so parts lookup should be closed.
    } else {
      await onSaveHandler({ serviceParts, totalPartsPrice });
      window.dispatchEvent(
        new CustomEvent("getEmergencyPartsUpdated", {
          detail: {},
          bubbles: true,
          cancelable: true
        })
      );
    }
  };
  const openLookupModal = () => {
    ctxGtmEvent?.trackGAEventWithParam("ga.newquote.modify_parts_click", {
      result: `${service?.operationSource} - ${service?.name}`
    });
    setShowModal(true);
  };
  // close modal should behave same like done click
  const onCloseModal = () => {
    // If action type is edit, carry serviceParts from parts context to service
    if (actionType !== actionTypes.MODIFY_PARTS) {
      setAddedParts(serviceParts);
      setShowModal(false);
      // Else, action type is summary, so parts lookup shoule be closed.
    } else {
      onCancelHandler();
    }
  };
  // @todo-poc: remove part local handler to callback prop handler to push un-deleted parts to Details.js
  // selected-parts-grid returns object {undeleted parts, deletedItem, totalParts}
  const updatePartsInContext = (partsResult, removing = false) => {
    // update context to add back deleted part to first grid
    if (!isEmpty(partsResult)) {
      const { deletedItem, parts } = partsResult;
      if (!isEmpty(partsResult.deletedItem)) {
        deletedItem.selected = false;
        // @todo-poc: Since deleted part has modified values, don't push it in lookupPartsList context,
        // instead get same part record from props.lookupParts and push this original part to lookupPartsList context
        const rawParts = cloneDeep(lookupPartsList);
        // Currently, only menu services are being passed originalParts. Other services can use lookupParts as reference.
        const referenceParts = !isEmpty(menuServiceOriginalParts)
          ? menuServiceOriginalParts
          : props.lookupParts;
        const orgPart = findPart(referenceParts, deletedItem);
        if (orgPart) rawParts.push(orgPart);
        resetDmsPending(rawParts, false);
        dispatch({
          type: Actions.SET_LOOKUP_PARTS_LIST,
          payload: rawParts
        });
      }
      // Note: If we are removing a part we don't want to set service parts again
      if (!removing) {
        dispatch({
          type: Actions.SET_SERVICE_PARTS,
          payload: parts
        });
      }
      // @todo: special case: when part deleted directly from edit service {all service types}
      setAddedParts(parts);

      if (actionType !== actionTypes.MODIFY_PARTS) {
        getPartsCallback(partsResult); // inspect this callback finally partsResult.parts to be saved in service
      }
    }
  };

  const handleShowCorePartReturnModal = data => {
    const rowId = generatePartId();
    const extPartId = rowId;
    const partId = rowId.toString();
    setCoreReturnPart({
      ...data,
      rowId,
      partId,
      extPartId,
      quoteServicePartId: null,
      origPartPrice: data.partPrice,
      origQuantity: data.quantity,
      coreReturnId: data.quoteServicePartId
    });
    setShowCorePartReturnModal(true);
  };

  const onCancelCoreReturnPart = () => setShowCorePartReturnModal(false);

  const onSaveCoreReturnPart = coreReturnPart => {
    const { partName, partPrice, quantity, oemPartNumber } = coreReturnPart;
    const unitPrice = (partPrice / quantity).toFixed(2);
    const costPrice = null;
    const newCoreReturnPart = {
      ...coreReturnPart,
      costPrice,
      partName: `${partName} Return`,
      partPrice: -partPrice,
      unitPrice: -unitPrice,
      oemPartNumber: replaceLastCWithX(oemPartNumber),
      quantity
    };
    setCoreReturnPart(null);
    const updatePartIndex = addedParts?.findIndex(
      part => part?.quoteServicePartId === newCoreReturnPart?.coreReturnId
    );
    const updatedparts = [
      ...addedParts.slice(0, updatePartIndex + 1),
      newCoreReturnPart,
      ...addedParts.slice(updatePartIndex + 1)
    ];
    setAddedParts(updatedparts);
    dispatch({
      type: Actions.SET_SERVICE_PARTS,
      payload: updatedparts
    });
    const totalPartsPrice = round(calculateTotalPrice(updatedparts), 2);
    onPerformPartAction({
      actionType: "CoreReturn",
      serviceParts: updatedparts,
      totalPartsPrice
    });
    setShowCorePartReturnModal(false);
  };

  const checkUpdateButtonDisabled = () => {
    let buttonDisabled = false;
    if (state?.apiCallPending) return true;
    if (isPartsView) {
      const hasPartsWithoutCost = serviceParts?.some(p => p?.unitCost === null);
      const hasPartsWithoutType = serviceParts?.some(part => {
        const { quantity, quantityAvailable, purchaseType, partType } = part;
        const inStock = isPartInstock(quantity, quantityAvailable);
        return !inStock && !purchaseType && partType !== "fluid";
      });
      buttonDisabled = hasPartsWithoutCost || hasPartsWithoutType;
    }
    return buttonDisabled;
  };

  // todo: convert to translation string once locale strings are passed to this submodule
  const modalTitle = `Modify parts`;
  const serviceName = service.name;
  const lookupGrids = (
    <ResultsPaneWrapper
      ref={partsGridLookupRef}
      parts={lookupPartsList}
      lookupParts={lookupParts}
      showNotes={showNotes}
      service={service} // @note - pass service used to check override in SelectedPartsGrid
      selectedVehicleAttributeMap={selectedVehicleAttributeMap}
      getPartsCallback={getPartsCallback}
      onPartPricingLookup={props.onPartPricingLookup}
      onPartCostChange={props.onPartCostChange}
      isEditService={actionType !== actionTypes.MODIFY_PARTS}
      vehicle={props.vehicle}
      commonConsumerId={commonConsumerId}
      actionType={actionType}
      isPartsView={isPartsView}
      isCsrFinalized={isCsrFinalized}
      isEmergencyPartsFlagOn={emergencyPartProperty === "Y" ? true : false}
    />
  );
  const partsLookupModal = (
    <ModalDialog
      htmlId="partsLookupModal"
      className="parts-lookup-modal"
      show={showModal}
      backdrop="static"
      header={
        <>
          <h6 className={`${themePrefixer()}modal-title`}>{modalTitle}</h6>
          <h4 className={`${themePrefixer()}service-name`}>{serviceName}</h4>
        </>
      }
      footer={
        <div className="lookup-modal-footer">
          <SubmitButton
            htmlId="addPartDoneBtn"
            isLoading={state?.apiCallPending}
            buttonStyle="secondary"
            onClick={doneHandler}
            disabled={checkUpdateButtonDisabled()}
          >
            Update
          </SubmitButton>
        </div>
      }
      onHide={onCloseModal}
    >
      {lookupGrids}
      {actionType === actionTypes.MODIFY_PARTS && dealershipNotes
        ? dealershipNotes
        : null}
    </ModalDialog>
  );

  const onRemovePart = params => {
    onPerformPartAction(params);
    setShowRemovePart(false);
  };

  const removePartModal = showRemovePart ? (
    <RemovePartModal
      show={showRemovePart}
      removePartData={removePartData}
      inventoryPartsLocation={inventoryPartsLocation}
      onCancel={() => setShowRemovePart(false)}
      onSave={onRemovePart}
      isCorePart={!!removePartData?.part?.isCorePart}
    />
  ) : (
    ""
  );

  const handleRemovePart = data => {
    setRemovePartData(data);
    setShowRemovePart(true);
  };

  const coreReturnModal = showCorePartReturnModal ? (
    <CorePartReturnModal
      show={showCorePartReturnModal}
      coreReturnPart={coreReturnPart}
      onCancel={onCancelCoreReturnPart}
      onSave={onSaveCoreReturnPart}
    />
  ) : (
    ""
  );

  const userHasPermissions = isNull(
    userPermissions.hasUpdateQuoteServicePartPermission
  )
    ? false
    : userPermissions.hasUpdateQuoteServicePartPermission;

  const servicePartsGrid = (
    <div className="sq-parts-flex-container">
      <SelectedPartsGrid
        title="Parts list"
        parts={addedParts}
        showPartId={false}
        getParts={updatePartsInContext}
        quoteSummary={quoteSummary}
        service={service}
        enableEdit={userHasPermissions}
        // @ERP: for approving/upadting part
        onPerformPartAction={onPerformPartAction}
        showRemovePartModal={handleRemovePart}
        showCorePartReturnModal={handleShowCorePartReturnModal}
        showActions={isPartsView ? !isCsrFinalized : null}
        isEmergencyPartsFlagOn={emergencyPartProperty === "Y" ? true : false}
        getPurchaseOrderDetails={getPurchaseOrderDetails}
        // isEditService={actionType === actionTypes.EDIT_SERVICE}
      />
    </div>
  );
  const clsBtn =
    userHasPermissions === false
      ? "hide-ele"
      : showLookupBtn
      ? "parts-lookup-flex-bgroup"
      : "hide-ele";

  const PartsLookupEdit = (
    <>
      {servicePartsGrid}
      <div className={clsBtn}>
        <Button
          htmlId="partsLookupModalBtn"
          type="button"
          buttonStyle="secondary"
          onClick={openLookupModal}
          disabled={!userHasPermissions}
          hidden={isPartsView ? appType === applications.CSR : null}
        >
          Modify parts
        </Button>
      </div>
    </>
  );

  return (
    <>
      {actionType !== actionTypes.MODIFY_PARTS ? PartsLookupEdit : null}
      {partsLookupModal}
      {removePartModal}
      {coreReturnModal}
    </>
  );
};

export const PartsLookupModule = props => {
  return (
    <PartsLookupProvider ctxGtmEvent={props.gtmEvent}>
      <PartsLookupComponent {...props} />
    </PartsLookupProvider>
  );
};

PartsLookupModule.defaultProps = {
  debugMode: false,
  userPermissions: {},
  // @required - when parts are added to quoteService and when same quoteService edited in summary page
  savedParts: [],
  // @optional - formatted edit module service, used from summary level quoteService edited, quoteService passed in this prop
  service: {
    name: "",
    partPrice: 0,
    parts: []
  },
  selectedVehicleAttributes: null,
  // @optional - to support globaloperation case - refactor checks
  completedSteps: null,
  // non-globalops case: read parts from unified API response;
  // globalops case: read parts from Action SET_LABOR_APP updated from unified API response;
  // summary flow: parts read from quoteService.quoteRawSerivce.rawService;
  // @required - read parts from catalog API service.parts
  lookupParts: [],
  // @prop used when search level when opentrack API callback made, this props has dynamic dmsParts to refresh parts grid
  partsPricingAndInventory: [],
  inventoryPartsLocation: [],
  // @prop make requried to apply filter logic on Make dropdown in Individual Part Tab
  make: null,
  dealerProperties: {},
  dealerMakesProperty: null,
  actionType: actionTypes.MODIFY_PARTS,
  getPartsCallback: () => {},
  onCancelHandler: () => {},
  onSaveHandler: () => {},
  onServiceChange: () => {},
  onPerformPartAction: () => {}, // @ERP
  onPartPricingLookup: () => {}, // @ERP
  onPartCostChange: () => {}, // @ERP
  gtmEvent: {
    trackGAEvent: () => {},
    trackGAEventWithParam: () => {},
    trackGAError: () => {},
    trackGAUnknowError: () => {}
  },
  appType: applications.QUOTING,
  isPartsView: false,
  isCsrFinalized: false,
  dealershipNotes: null
  // @ERP - quoteStatus for enabling/disabling the Remove button in action menu
};

PartsLookupModule.prototype = {
  // @required props
  appType: PropTypes.string,
  debugMode: PropTypes.bool,
  userPermissions: PropTypes.object,
  // props used to trigger callback to capture events from partslookup comp in Google Analytics.
  gtmEvent: PropTypes.shape({
    trackGAEventWithParam: PropTypes.func,
    trackGAEvent: PropTypes.func,
    trackGAError: PropTypes.func,
    trackGAUnknowError: PropTypes.func
  }),
  // required for quoting service
  dealershipNotes: PropTypes.element,
  // @required- - expected values "EDIT_SERVICE" or "MODIFY_PARTS"
  // when service edited from summary, we can depend on editcontext -> state.service
  actionType: PropTypes.string,
  // @required- make passed to support Make dropdown filter logic in Add Individual Part Tab
  make: PropTypes.string,
  dealerProperties: PropTypes.object,
  dealerMakesProperty: PropTypes.string,
  /*
  @required - Add Service case (search, non-global) - This prop hold catalog getOperation API response level laborApps[0].parts
  Add Service case (global repair service): state.lookupParts updated using Action SET_LABOR_APP
  Summary edit case: read QuoteRawService Json string for parts[] from quoteService;
  */
  lookupParts: PropTypes.array,
  // @required- - All flows - pass parts of opentrack API callback response, to refresh recommended grid and selected parts grid as well
  partsPricingAndInventory: PropTypes.array,
  inventoryPartsLocation: PropTypes.array,
  /* @required prop - 
  - Service object used to read service name, operationSource(global, non-global, menu, price override fields) for conditions.
  - Add service case: parts exist in serivce.parts(non-global);,filteredParts(global);
  - Edit service summary case: (global/non-global)- parts are saved in "filteredParts" using this Action SET_CURRENT_EDITING_SERVICE
  */
  service: PropTypes.object,
  // TBD case: Either remove this prop and use service.parts condtions in parts lookup files; This prop updated in edit saved service from summary page with filteredParts -> parts extracted from quoteService
  savedParts: PropTypes.array,
  quoteSummary: PropTypes.object,
  // @optional prop - used when global repair service has undecode vehicle attrs to show extra grid columns
  selectedVehicleAttributes: PropTypes.object,
  // @optional: global repair case, these steps used whether service options dropdown selected, to allow used to click on "MODIFY Parts" button in edit service page. (TBD - find alternative)
  completedSteps: PropTypes.object,
  // @required: This callback handler to compare before/after edited parts in parts modal, used to update total parts price based on modifed parts state (called used in Details.js)
  getPartsCallback: PropTypes.func,
  // Modify Parts case: This handler when we close parts modal without saving changes, shows speed bump
  onCancelHandler: PropTypes.func,
  // Modify Parts case: This handler to save modified/added parts inside SelectedPartsGrid, will be saved under service of a quote
  onSaveHandler: PropTypes.func,
  // Modify Parts case: Handler to used for local state to track when service is switched
  onServiceChange: PropTypes.func,
  // ERP case: Handler used as callback to support Action column items {Approve, Special order, Emergency purchase, Remove } in Parts Dashboard case
  onPerformPartAction: PropTypes.func,
  // Callback handler: used to fetch DMS data - parts inventory list for added parts under service
  onPartPricingLookup: PropTypes.func,
  // Callback handler:
  onPartCostChange: PropTypes.func,
  // CSR: Optional prop - Used for Parts Dashboard case
  isPartsView: PropTypes.bool,
  // CSR: Optional
  isCsrFinalized: PropTypes.bool
};
