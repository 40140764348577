import { getServiceContracts } from "../services/service-contracts.service";
import isArray from "lodash/isArray";
import { getCustomerAndVehicle } from "../../page-wrapper/services/customer-vehicle.service";
import isEmpty from "lodash/isEmpty";

/**
 * Retrieves service contracts information based on dealer code, VIN, and common consumer ID.
 * If 'TEST_HOOK_NO_DATA_FOUND' is set to '1' in the URL parameters, simulates a data retrieval failure.
 *
 * @async
 * @function getServiceContractsInfo$
 * @param {string} dealerCode - The code of the dealer.
 * @param {string} vin - The vehicle identification number.
 * @param {string} commonConsumerId - The common consumer ID.
 * @param {string} schemaName - The schema name for the database.
 * @returns {Promise<Object|null>} The response from the service contracts retrieval or null if an error occurs.
 * @throws Will throw an error if the data retrieval fails.
 */
const getServiceContractsInfo$ = async (
  dealerCode,
  vin,
  commonConsumerId,
  schemaName
) => {
  try {
    const searchParams = new URLSearchParams(window.location.search);
    const testHook = searchParams.get("TEST_HOOK_NO_DATA_FOUND") === "1";

    if (testHook) {
      throw new Error(`Failed to retrieve data for ${vin || "this vehicle"}`);
    }

    const vehicleVin = searchParams.get("vin") || vin;
    const customerCommonConsumerId =
      searchParams.get("commonConsumerId") || commonConsumerId;
    const vehicleId = searchParams.get("vehicleId");
    const customerId = searchParams.get("customerId");

    if (
      isEmpty(vehicleVin) &&
      isEmpty(customerCommonConsumerId) &&
      !isEmpty(vehicleId) &&
      !isEmpty(customerId)
    ) {
      const { customer, vehicle } = await getCustomerAndVehicle({
        customerId,
        vehicleId,
        schemaName,
        dealerCode
      });

      return await getServiceContracts({
        dealerCode,
        vin: vehicle?.vin,
        commonConsumerId: customer?.ccId
      });
    }

    if (vehicleVin) {
      return await getServiceContracts({
        dealerCode,
        vin: vehicleVin,
        commonConsumerId: customerCommonConsumerId
      });
    }

    return [];
  } catch (error) {
    console.error("Error retrieving service contracts:", error);
    return null;
  }
};

/**
 * Formats vendor and product names into a string.
 *
 * @param {string} vendorName - The name of the vendor.
 * @param {string} productName - The name of the product.
 * @returns {string} A formatted string with the vendor and product names, or just one if the other is unavailable.
 * - If both vendorName and productName are provided, returns `${vendorName} - ${productName}`.
 * - If only vendorName is provided, returns the vendorName.
 * - If only productName is provided, returns the productName.
 * - If neither is provided, returns an empty string.
 */
function formatVendorProduct(vendorName, productName) {
  if (vendorName && productName) {
    return `${vendorName} - ${productName}`;
  } else if (vendorName) {
    return vendorName;
  } else if (productName) {
    return productName;
  } else {
    return "";
  }
}

/**
 * Transforms a list of service contracts into a list of vendors.
 *
 * @function getVendorListTransformed
 * @param {Array<Object>} serviceContracts - The list of service contracts.
 * @returns {Array<Object>} A list of objects each containing a vendor name.
 */
const getVendorListTransformed = serviceContracts => {
  let vendorList = [];

  if (isArray(serviceContracts))
    vendorList = serviceContracts?.map(sc => ({
      vendor: formatVendorProduct(sc?.vendorName, sc?.productName),
      internalProductId: sc.internalProductId
    }));

  return vendorList || [];
};

export {
  getServiceContractsInfo$,
  getVendorListTransformed,
  formatVendorProduct
};
