const CONFIG = {
  diagnosis: {
    searchHeader: true,
    vehicleAttr: false,
    serviceOptions: false,
    description: true,
    asrNotes: false,
    parts: true,
    laborComponent: true,
    partsLookup: true,
    partsGrid: true,
    csrDetails: false, 
    notes: true,
    dealershipNotes: true
  },
  dealerPublished: {
    searchHeader: true,
    vehicleAttr: false,
    serviceOptions: false,
    description: true,
    asrNotes: false,
    laborComponent: true,
    partsLookup: true,
    partsGrid: true,
    notes: true,
    dealershipNotes: true,
    csrDetails: false, 
    parts: true
  },
  declined: {
    searchHeader: true,
    vehicleAttr: false,
    serviceOptions: false,
    description: true,
    asrNotes: true,
    laborComponent: true,
    partsLookup: true,
    partsGrid: true,
    csrDetails: false, 
    notes: true,
    dealershipNotes: true
  },
  recall: {
    searchHeader: true,
    vehicleAttr: false,
    serviceOptions: false,
    description: true,
    asrNotes: false,
    laborComponent: true,
    partsLookup: true,
    partsGrid: true,
    csrDetails: false, 
    notes: false,
    dealershipNotes: true
  },
  globalRepair: {
    searchHeader: true,
    vehicleAttr: true,
    serviceOptions: true,
    description: true,
    asrNotes: false,
    laborComponent: true,
    partsLookup: true,
    partsGrid: true,
    csrDetails: false, 
    notes: true,
    dealershipNotes: true
  },
  menu: {
    searchHeader: true,
    vehicleAttr: false,
    serviceOptions: false,
    description: true,
    asrNotes: false,
    laborComponent: true,
    partsLookup: true,
    partsGrid: true,
    csrDetails: false, 
    notes: true,
    dealershipNotes: true
  },
  tellusMore: {
    searchHeader: true,
    vehicleAttr: false,
    serviceOptions: false,
    description: false,
    asrNotes: false,
    laborComponent: true,
    partsLookup: true,
    partsGrid: true,
    csrDetails: false, 
    notes: true,
    dealershipNotes: false
  }
};

// @csr-logic - update configuration based on appType
function updateEditSettings(appType){
  const settings = CONFIG;
  if(appType === "CSR"){
    settings.diagnosis.csrDetails = true;
    settings.dealerPublished.csrDetails = true;
    settings.globalRepair.csrDetails= true;
    settings.menu.csrDetails = true;
  }
  return settings;
}

export {
  CONFIG,
  updateEditSettings
};
